import 'babel-polyfill';
import 'whatwg-fetch';
import 'main.scss';

// Support component names relative to this directory: 
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)




