import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import Loading from '../misc/Loading.jsx';
import fetchErrorHandler from '../errors/fetchErrrorHandler.js';
import readCookie from '../misc/readCookie.js';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NagRenderProgress from './nagcomponents/RenderProgress';
import Moment from 'react-moment';

class MFATokens extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: true,
      tokens: [],
      showDeleteModal: false,
      selectedToDelete: {
        serial: '',
        type: '',
        description: ''
      },
      last_enrolled: '',
      error_message: '',
      allow_skip: false
    }

    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);

    if (typeof this.props.nag_options !== 'undefined') {    
      if (this.props.nag_options['allow_skip'] && 
          this.props.nag_options['allow_skip_mfa']) {
        this.state['allow_skip'] = true;
      }
    }
  }

  handleDeleteModalOpen(token) {
    this.setState({
      showDeleteModal: true,
      selectedToDelete: token
    });
  }

  handleDeleteModalClose() {
    this.setState({
      showDeleteModal: false,
      selectedToDelete: {
        serial: '',
        type: '',
        description: ''
      }
    });
  }

  async handleDelete(event) {
    event.preventDefault();
    

    this.setState({
      loading: true,
      showDeleteModal: false,
      selectedToDelete: {
        serial: '',
        type: '',
        description: ''
      }      
    });

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('mfa_serial', this.state.selectedToDelete.serial);
    formData.append('authenticity_token', csrf_token)

    try {
      let result = await fetch('/tokens/revoke.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }
      
      if (data.status == "ok") {
        this.handleDeleteModalClose();          
        this.componentDidMount();
      } else {
        this.handleDeleteModalClose();
        this.setState({ error_message: data.message, });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }


  async componentDidMount() {

    if (typeof this.props.nag_options !== 'undefined' && this.props.nag_options.mfa_completed) {
      this.props.history.push("/");
    }  

    try {
      let result = await fetch('/tokens.json', {credentials: 'same-origin'})
      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        this.setState({
          tokens: data.data.tokens,
          last_enrolled: data.data.last_enrolled,
          loading: false
        })
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  renderNagProgress() {
    return(
      <React.Fragment>
        <NagRenderProgress nag_options={this.props.nag_options} current="mfa" />
      </React.Fragment>
    )
  }

  renderToken(token,i) {

    if (token.type == 'sms') {
      token.type = 'SMS';
    }
    
     return (
      <tr key={i}>
        <td>{token.serial}</td>
        <td>{token.description}</td>
        <td>{token.type}</td>
        <td>
          <div className="pull-right">
            <button className="btn btn-success" 
                    onClick={(e) => this.props.history.push('/mfa/' + token.serial)}>Edit</button>&nbsp;
            <button className="btn btn-danger" onClick={(e) => this.handleDeleteModalOpen(token)}>Delete</button>
          </div>
        </td>
      </tr>
    )
  }  

  renderError() {
    if (this.state.error_message) {
      return(
        <div className="alert alert-danger">{this.state.error_message}</div>
      );
    }
    return;
  }

  renderLastEnrolled() {
    if (this.state.last_enrolled) {

    let last_enrolled = this.state.last_enrolled;

    if (/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}\s[0-9]{2}\:[0-9]{2}\:[0-9]{2}\s\+[0-9]{4}$/.test(last_enrolled)) {
      last_enrolled = <Moment parse="YYYY-MM-DD HH:mm:ss Z" format="DD/MM/YYYY HH:mm">{last_enrolled}</Moment>;
    }

      return (
        <div>
          <em>Last enrolled: {last_enrolled}</em>
        </div>
      )
    }
  }

  renderAlreadyRegisteredText() {
    if (this.state.last_enrolled) {
      let last_enrolled = this.state.last_enrolled;

      if (/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}\s[0-9]{2}\:[0-9]{2}\:[0-9]{2}\s\+[0-9]{4}$/.test(last_enrolled)) {
        last_enrolled = <Moment parse="YYYY-MM-DD HH:mm:ss Z" format="DD/MM/YYYY HH:mm">{last_enrolled}</Moment>;
      }

      return (
        <p>
          You have already registered for MFA on {last_enrolled}. You can register 
          additional factors or edit those already registered using the buttons below.
        </p>
      )
    }
  }

  renderNewTokenBtn(type) {
    if (type == 'totp' && window.gon.token_types.indexOf('totp') > -1) {
      return (
        <Link to="/mfa/otp/new" className="btn btn-primary">Smartphone app</Link>
      );
    } else if (type == 'sms' && window.gon.token_types.indexOf('sms') > -1) {
      return (
        <Link to="/mfa/sms/new" className="btn btn-primary">SMS text</Link>
      );
    } else if (type == 'email' && window.gon.token_types.indexOf('email') > -1) {
      return (
        <Link to="/mfa/email/new" className="btn btn-primary">E-mail</Link>    
      );
    } else if (type =='hw' && window.gon.token_types.indexOf('hw') > -1) {
      return (
        <Link to="/mfa/hw/new" className="btn btn-primary">Hardware</Link>
      );
    }
  }

  renderTokenTable() {
    var self=this;
    return(
      <div>
       {self.renderAlreadyRegisteredText()}

       <table className="table table-striped">
        <thead>
          <tr>
            <th>
              Token ID &nbsp;
              <OverlayTrigger
                key="tokenid-tooltip-overlay"
                placement="bottom"
                overlay= {
                  <Tooltip id="tokenid-tooltip">
                    These are the unique IDs that corrospond to your tokens
                  </Tooltip>
                }
               >
                 <i className="fa fa-question-circle"></i>
               </OverlayTrigger>
            </th>
            <th>
              Description &nbsp;
              <OverlayTrigger
                key="tokendesc-tooltip-overlay"
                placement="bottom"
                overlay= {
                  <Tooltip id="tokendesc-tooltip">
                    This column contains the description of your tokens that you
                    rovided whilst setting up your multi factor authentication
                  </Tooltip>
                }
               >
                 <i className="fa fa-question-circle"></i>
               </OverlayTrigger>
            </th>
            <th>
              Type &nbsp;
              <OverlayTrigger
                key="type-tooltip-overlay"
                placement="bottom"
                overlay= {
                  <Tooltip id="tokentype-tooltip">
                    This column contains the type of a given token
                  </Tooltip>
                }
               >
                 <i className="fa fa-question-circle"></i>
               </OverlayTrigger>                
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {this.state.tokens.map(function(token, i) {
            return self.renderToken(token, i);
          })}
        </tbody>
      </table>        

      {this.renderLastEnrolled()}
    </div>      
    );
  }

  renderTokenButtons(includeNag=false) {
    let nagButton = null;

    if (includeNag) {
      nagButton = this.renderNagSkipButton();
    }

    return (
     <div>

      <h4>Register</h4>

      {this.renderNewTokenBtn('totp')}&nbsp;
      {this.renderNewTokenBtn('sms')}&nbsp;
      {this.renderNewTokenBtn('hw')}&nbsp;
      {this.renderNewTokenBtn('email')}&nbsp;

      {nagButton}


      <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure that you want to delete  {this.state.selectedToDelete.serial}?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <strong>Token ID: </strong> {this.state.selectedToDelete.serial} <br />
            <strong>Description: </strong> {this.state.selectedToDelete.description} <br />
            <strong>Type: </strong> {this.state.selectedToDelete.type}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleDeleteModalClose}>Close</button>
          <button className="btn btn-danger" onClick={this.handleDelete}>Delete</button>
        </Modal.Footer>
      </Modal>
     </div>
    )
  }

  renderNagSkipButton() {
    if (this.state.allow_skip) {
      return (
        <button className="btn btn-primary pull-right" 
                type="button"
                onClick={(e) => this.props.nagSkipIndividal(this,"mfa", e)}>
          Skip (remind me later)
        </button>
      );
    }
  }

  render() {
    var self=this;
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <Loading loading={this.state.loading} />
      );
    }

    if (typeof this.props.nag_options !== 'undefined') {

      let nag_text = "We notice that you haven't yet setup your multi factor " + 
          "authentication, if you would like to do that now then please follow "+
          "the instructions below, or click skip if you'd like to complete them later.";


      if (!this.state.allow_skip) {
        nag_text = "We notice that you haven't yet setup your multi factor " + 
          "authentication, please follow the instructions below.";
      }

      return (
        <div>
          <h1>Account status</h1>
          {this.renderNagProgress()}    

          <div className="nag-padded">
            <p>
              <strong>
                {nag_text}
              </strong>
            </p>

            <h3>Setup multi factor authentication</h3>

            {this.renderError()}
            <p>
              Multi factor authentication adds extra security to your account by 
              requiring you to enter a one time password (OTP) code sent by SMS 
              or email or generated on your smartphone.
            </p>
            {this.renderTokenButtons(true)}      
          </div>
        </div>
      )
    }

    return (
      <div>
        <h1>Setup multi factor authentication</h1>
        {this.renderError()}

        {this.renderTokenTable()}
        {this.renderTokenButtons()}
        
      </div>  
    )
  }
}

export default MFATokens
