import React from "react";
import PropTypes from "prop-types";
import { HashRouter, Route, Switch, Link } from "react-router-dom";
import Loading from './misc/Loading.jsx';
import readCookie from './misc/readCookie.js';
import fetchErrorHandler from './errors/fetchErrrorHandler.js';
import Error404 from './errors/Error404';
import Error500 from './errors/Error500';

import Home from './portal/Home';
import NagScreen from './portal/NagScreen';
import MyAccount from './portal/MyAccount';

import MFATokens from './portal/MFATokens';
import MFANewOTP from './portal/mfa/NewOTP.jsx';
import MFANewSMS from './portal/mfa/NewSMS.jsx';
import MFANewEmail from './portal/mfa/NewEmail.jsx';
import MFAEdit from './portal/mfa/Edit.jsx';

import Challenges from './portal/challenge/Challenges.jsx';
import ChallengeNew from './portal/challenge/New.jsx';
import ChallengeNewLdap from './portal/challenge/NewLdap.jsx';

import ChangePassword from './portal/changepassword/ChangePassword';

import PrivacyPolicy from './misc/PrivacyPolicy';


class Portal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      caughtError: '',
      inactivity_remain: -1000,
      inactivity_timer: false,
      inactivity_update_timer: false,
      show_nag_one_last_time: false,
      nag_options: {
        registration_completed: false,
        mfa_completed: false,
        mfa_last_enrolled: '',
        challenges_completed: false,
        challenges_last_updated:'',
        sso_login: false,
        redirect_path: '../'
      }      
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.inactivity_tick = this.inactivity_tick.bind(this);
    this.expireCheck = this.expireCheck.bind(this);
    this.skipIndividialAPI = this.skipIndividialAPI.bind(this);
  }

  async handleLogout(event) {
    event.preventDefault();

    this.doLogout();
  }

  async doLogout() {
    let result = await fetch('/logout.json', {credentials: 'same-origin'});
    result = await fetchErrorHandler(result);
    const data = await result.json();

    if (data.status == "ok") {
      window.location.href = data.logout_url;      
    } else {
      window.location.href = "/login";
    }    
  }

  resetInactivityTimer() {
    this.setState({
      inactivity_remain: 600
    });
  }

  removeNag() {
    if (!this.state.nag_options.sso_login || !this.state.nag_options.redirect_path) {
      this.setState({
        loading: false,
        show_nag_one_last_time: false, // Disable old nag screen
        nag_options: {
          registration_completed: true // Disable old nag screen
        }
      });  

      return false;
    } else {
      return this.handleRedirect(false);
    }
  }

  // This allows us to have seperate counters for skipping 
  // MFA, Challenges, Ldap Challenges, etc
  // And set different maximums for each
  async skipIndividialAPI(name) {
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('skip_type', name);
    formData.append('authenticity_token', csrf_token)

    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/skip_registration.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.resetInactivityTimer();
      }

      if (data.status == "ok") {
        this.setState({ 
          loading: false,  
          nag_options: data.data,
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }   
  }  



  async nagSkipIndividal(self,name, event) {
    event.preventDefault();
    let redirect_path = "handle_redirect";

    if (
      !self.props.nag_options.challenges_completed &&
      name != 'challenges' &&
      name != 'ldap_challenges' && 
      name != 'mfa'
    ) {
      redirect_path = "/challengeresponse/new";
    } else if (
     (
       self.props.nag_options.ldap_chal_enabled ||
       self.props.nag_options.ldap_chap_show_alt 
     ) && 
     !self.props.nag_options.ldap_chal_completed &&
     name != 'ldap_challenges' &&
     name != 'mfa'
    ) {
      redirect_path = "/myaccount/personal_challenges";
    } else if (!self.props.nag_options.mfa_completed && name != 'mfa') {
      redirect_path = "/mfa";
    }

    await self.props.skipIndividialAPI(name);

    if (redirect_path != "handle_redirect") {
      self.props.history.push(redirect_path);
    } else {
      if (!self.props.handleRedirect(true)) {
        self.props.history.push("/");
      }
    }
  }

  handleRedirect(skip=false) {
    if (skip) {
      if (!this.state.nag_options.sso_login) {
        this.setState({
          show_nag_one_last_time: false,
          nag_options: {
            registration_completed: true,
            loading: false,
          }
        });

        return false;
      } else {
        window.location.href = this.state.nag_options.redirect_path;  
        return true;
      }
    } else {
      window.location.href = this.state.nag_options.redirect_path;
      return true;
    }
  }

  pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  secondsToMinSecs(seconds) {

    if (seconds == -1000) {
      return "";
    }

    let min = Math.floor(seconds / 60);
    let sec = seconds - (min * 60)

    if (min < 0) {
      min = 0;
      sec = 0;
    }

    if (sec < 0) {
      sec = 0;
    }

    min = this.pad(min, 2);
    sec = this.pad(Math.floor(sec), 2);

    return min + ":" + sec;
  }

  inactivity_tick() {
    let seconds = this.state.inactivity_remain;
    if (seconds == -1000) {
      return;
    }

    seconds --;

    
    if (seconds < -2) {
      var self=this;
      setTimeout(function() {
        self.doLogout();
      }, 1500);
    }

    this.setState({
      inactivity_remain: seconds
    });
  }

  async expireCheck() {
    try {
      let result = await fetch("/login_expire_check.json", {credentials: 'same-origin'});
      const data = await result.json();
      if (data.status == "ok") {
        this.setState({
          inactivity_remain: data.data.expires
        });
      }
    } catch (error) {

    }
  }


  async checkIfWeShouldNag() {
    try {
      let result = await fetch('/check_registration_complete.json', {credentials: 'same-origin'})
      result = await fetchErrorHandler(result);
      const data = await result.json();
      
      if (data.status == "ok") {
        let nag_options = data.data;
        this.setState({
          nag_options: nag_options,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({caughtError: error});      
    }
  }


  async componentDidMount() {
    var self = this;

    this.checkIfWeShouldNag();

    this.expireCheck();

    if (this.state.inactivity_timer) {
      clearTimeout(this.state.inactivity_timer);
    }

    this.setState({
      inactivity_timer: setInterval(function() {
        self.inactivity_tick();
      }, 1000)
    });

    if (this.state.inactivity_update_timer) {
      clearTimeout(this.state.inactivity_update_timer);
    }

    this.setState({
      inactivity_update_timer: setInterval(function() {
        self.expireCheck();
      }, 30000)      
    })
  }

  renderInactivityTimer() {
    if (this.state.inactivity_remain != -1000) {
      return(
        <em>If you’re inactive you will be logged out in {this.secondsToMinSecs(this.state.inactivity_remain)}</em>
      );
    } else {
      return;
    }    
  }

  render() {

    let privacypolicy = "";

    if (gon.show_privacy_policy && !window.location.href.includes('privacypolicy')) {
      privacypolicy = (
          <p className="portal_footer">
            <Link to="/privacypolicy">Privacy Policy</Link>
          </p>
      );
    }    

    if (this.state.loading) {
      return (
        <div className="container">        
          <div className="row">
            <div className="col-sm-12 portal">
              <Loading loading={this.state.loading} />
            </div>
          </div>
        </div>
      );
    }

    if (!this.state.nag_options.registration_completed || this.state.show_nag_one_last_time) {
      return (
        <HashRouter>
          <div className="container">
            <div className="row">
              <div className="col-md-1 col-sm-12 portal-logo">
                <Link to='/'><img src={window.gon.logo_url} /></Link>
              </div>
              <div className="col-md-11 col-sm-12">
                <div className="portal-links">
                  <Link to='/'><i className="fa fa-home"></i></Link>
                  <i className="fa fa-sign-out" onClick={this.handleLogout}></i>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-sm-12 portal">
                <Error500>
                  <Switch>
                    <Route exact path="/challengeresponse/new" render={
                          (props) => <ChallengeNew {...props} nag_options={this.state.nag_options} 
                                                              remove_nag={this.removeNag.bind(this)}
                                                              handleRedirect={this.handleRedirect.bind(this)}
                                                              skipIndividialAPI={this.skipIndividialAPI.bind(this)}
                                                              nagSkipIndividal={this.nagSkipIndividal} />} />

                    <Route exact path="/myaccount/personal_challenges" render = {                        
                      (props) => <ChallengeNewLdap {...props} 
                                                               nag_options={this.state.nag_options} 
                                                               remove_nag={this.removeNag.bind(this)}
                                                               handleRedirect={this.handleRedirect.bind(this)}
                                                               skipIndividialAPI={this.skipIndividialAPI.bind(this)}
                                                               nagSkipIndividal={this.nagSkipIndividal}/>} />                                                            

                    <Route exact path="/mfa" render={
                          (props) => <MFATokens {...props} nag_options={this.state.nag_options} 
                                                           remove_nag={this.removeNag.bind(this)}
                                                           handleRedirect={this.handleRedirect.bind(this)}
                                                           skipIndividialAPI={this.skipIndividialAPI.bind(this)}
                                                           nagSkipIndividal={this.nagSkipIndividal}/>} />

                  <Route exact path="/mfa/otp/new"  render={
                    (props) => <MFANewOTP {...props} nag_options={this.state.nag_options} 
                                                    remove_nag={this.removeNag.bind(this)}
                                                    handleRedirect={this.handleRedirect.bind(this)}
                                                    skipIndividialAPI={this.skipIndividialAPI.bind(this)}
                                                    nagSkipIndividal={this.nagSkipIndividal} />} />
                  <Route exact path="/mfa/sms/new"  render={
                    (props) => <MFANewSMS {...props} nag_options={this.state.nag_options} 
                                                     remove_nag={this.removeNag.bind(this)}
                                                     handleRedirect={this.handleRedirect.bind(this)}
                                                     skipIndividialAPI={this.skipIndividialAPI.bind(this)}
                                                     nagSkipIndividal={this.nagSkipIndividal} />} />

                  <Route exact path="/mfa/email/new"  render={
                    (props) => <MFANewEmail {...props} nag_options={this.state.nag_options} 
                                                       remove_nag={this.removeNag.bind(this)}
                                                       handleRedirect={this.handleRedirect.bind(this)}
                                                       skipIndividialAPI={this.skipIndividialAPI.bind(this)}
                                                       nagSkipIndividal={this.nagSkipIndividal} />} />                                                       

                   <Route exact path="/privacypolicy" component={PrivacyPolicy} />     

                    <Route render={
                          (props) => <NagScreen {...props} nag_options={this.state.nag_options} 
                                                           remove_nag={this.removeNag.bind(this)}
                                                           handleRedirect={this.handleRedirect.bind(this)}
                                                           nagSkipIndividal={this.nagSkipIndividal} />} />         

                                                                                                                   

                  </Switch>
                </Error500>
              </div>
            </div>  
            <div className="row">
              <div className="col-sm-12 footer">
                {privacypolicy}
              </div>
            </div>                    
          </div>                   
        </HashRouter>
      );
    }


    return(
      <HashRouter>
        <div className="container">
          <div className="row">
            <div className="col-md-1 col-sm-12 portal-logo">
              <Link to='/'><img src={window.gon.logo_url} /></Link>
            </div>
            <div className="col-md-11 col-sm-12">
              <div className="portal-links">
                <Link to='/'><i className="fa fa-home"></i></Link>
                <i className="fa fa-sign-out" onClick={this.handleLogout}></i>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 portal">
              <Error500>
                <div className="row">
                  <div className="col-sm-12">
                    <Switch>
                      <Route exact path="/" render={
                        (props) => <Home {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />

                      <Route exact path="/mfa"  render={
                        (props) => <MFATokens {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />
                      <Route exact path="/mfa/otp/new"  render={
                        (props) => <MFANewOTP {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />
                      <Route exact path="/mfa/sms/new"  render={
                        (props) => <MFANewSMS {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />
                      <Route exact path="/mfa/email/new"  render={
                        (props) => <MFANewEmail {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />                        
                      <Route exact path="/mfa/:mfa_serial"  render={
                        (props) => <MFAEdit {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />
                            
                      <Route exact path="/challengeresponse"  render={
                        (props) => <Challenges {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />
                      <Route exact path="/challengeresponse/new"  render={
                        (props) => <ChallengeNew {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />

                      <Route exact path="/changepassword"  render={
                        (props) => <ChangePassword {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />
                       

                      <Route exact path="/myaccount" render = {                        
                        (props) => <MyAccount {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />

                      <Route exact path="/myaccount/personal_challenges" render = {                        
                        (props) => <ChallengeNewLdap {...props} resetInactivityTimer={this.resetInactivityTimer.bind(this)}/>} />                        

                      <Route exact path="/privacypolicy" component={PrivacyPolicy} />
                      <Route component={Error404} />
                    </Switch>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 footer">
                    {this.renderInactivityTimer()}

                    {privacypolicy}
                  </div>
                </div>
              </Error500>
            </div>
          </div>
        </div>            
      </HashRouter>
    );
  }
}

export default Portal
