import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import Moment from 'react-moment';
import fetchErrorHandler from '../errors/fetchErrrorHandler.js';
import Loading from '../misc/Loading.jsx';

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      caughtError: '',
      attributes: [],
      ldap_challenges: [],
      ldap_challenges_uncompleted: [],
      ldap_challenge_edit_enabled: false,
      ldap_chal_alt_text: '',
      ldap_chal_alt_enabled: false
    }
  }

  async componentDidMount() {
    try {
      let result = await fetch('/my_account.json', {credentials: 'same-origin'});

      result = await fetchErrorHandler(result);
      const data = await result.json();
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }
      
      if (data.status == "ok") {
        this.setState({
          loading: false,
          attributes: data.data.attributes,
          ldap_challenges: data.data.ldap_challenges,
          ldap_challenge_edit_enabled: data.data.ldap_challenge_edit_enabled,
          ldap_challenges_uncompleted: data.data.ldap_challenges_uncompleted,
          ldap_chal_alt_text: data.data.ldap_chal_alt_text,
          ldap_chal_alt_enabled: data.data.ldap_chal_alt_enabled
        });
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  renderAttribute(name, value, i) {
    if (/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}\s[0-9]{2}\:[0-9]{2}\:[0-9]{2}\s\+[0-9]{4}$/.test(value)) {
      value = <Moment parse="YYYY-MM-DD HH:mm:ss Z" format="DD/MM/YYYY HH:mm">{value}</Moment>;
    }

    return (
      <tr key={i}>
        <th>{name}</th>
        <td>{value}</td>
      </tr>
    )
  }

  renderLdapChallengeEditButton() {
    if (this.state.ldap_challenge_edit_enabled) {
      return(
        <Link to='/myaccount/personal_challenges' className="btn btn-primary">
          Edit data
        </Link>
      )
    }
  }


  renderLdapChallenges() {
    if (this.state.ldap_challenges_uncompleted.length > 0
     &&
        this.state.ldap_challenge_edit_enabled) {
      return(
        <React.Fragment>
          <h3>Please complete the following:</h3>
          <ul>
            {this.state.ldap_challenges_uncompleted.map(function(question, i) {
              return(
                <li key={i}>{question}</li>
              )
            })}
          </ul>

          <Link to='/myaccount/personal_challenges' className="btn btn-primary">
            Complete data
          </Link>          
        </React.Fragment>  
      )
    } else if (this.state.ldap_challenges.length >= 1) {
      return (
        <React.Fragment>
          {this.renderLdapChallengeEditButton()}
        </React.Fragment>
      )
    } else if (this.state.ldap_chal_alt_enabled) {
      return (
        <React.Fragment>
          <h3>
            {this.state.ldap_chal_alt_text}
          </h3>

          <ul>
            {this.state.ldap_challenges_uncompleted.map(function(question, i) {
              return(
                <li key={i}>{question}</li>
              )
            })}
          </ul>
        </React.Fragment>
      )      
    }
  }

  render() {
    var self=this;
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <Loading loading={this.state.loading} />
      );
    } 

    return (
      <div>
        <h1>My account</h1>

        <p>
           Here you can see some information about your account, 
           the service desk may use this information to verify your identity
           when you call them.
        </p>

        <table className="table table-striped table-bordered">
          <tbody>
            {Object.keys(self.state.attributes).map(function(attribute, i) {
              return self.renderAttribute(attribute, self.state.attributes[attribute], i);
            })}
           </tbody>
        </table>

        {this.renderLdapChallenges()}

      </div>
    )
  }
}

export default MyAccount
