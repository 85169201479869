import React from "react"
import PropTypes from "prop-types";
import {Link} from "react-router-dom"

import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import Loading from '../../misc/Loading.jsx';

import RenderProgress from './RenderProgress';
import RenderError from '../../misc/RenderError';
import ChangePasswordForm from '../../misc/ChangePasswordForm';

class ForgotPasswordChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      caughtError: '',      
      error_message: '',
    }
    
    this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
  }

  async changePasswordSubmit(new_password, confirm_password, errorCallback) {

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));
    let formData = new FormData();
    formData.append('new_password', new_password);
    formData.append('confirm_password', confirm_password);
    formData.append('authenticity_token', csrf_token);

    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/session/forgotpassword.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (data.status == "ok") {
        this.props.childStateSet({
          step: 5
        });
      } else {
        errorCallback();

        this.setState({
          error_message: data.message,
          loading: false        
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }  



  render() {
    var self = this;

    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 portal">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    let top_class = "col-sm-12";

    if (typeof window.gon !== 'undefined' && typeof window.gon.sso !== 'undefined' && window.gon.sso == true) {
      top_class = top_class + " in-modal"
    }        

    let privacypolicy = "";

    if (gon.show_privacy_policy && !window.location.href.includes('privacypolicy')) {
      privacypolicy = (
        <p className="portal_footer">
          <Link to="/privacypolicy">Privacy Policy</Link>
        </p>
      );
    }     AbortController    
    return (
      <div className={top_class}>
        <div className="row">
          <div className="col-md-1 col-sm-12 portal-logo">
            <Link to='/'><img src={window.gon.logo_url} /></Link>
          </div>
          <div className="col-md-11 col-sm-12">
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <h1>Forgotten password</h1>        

            <RenderProgress step={4} />

            <RenderError error_message={this.state.error_message} />
            
             <ChangePasswordForm 
               changePasswordSubmit={this.changePasswordSubmit.bind(this)}
               password_policy_text={this.props.parentState.password_policy_text}
               password_policy={this.props.parentState.password_policy} />

          </div>
        </div>  
        <div className="row">
          <div className="col-sm-12 footer">
            {privacypolicy}
          </div>
        </div>                  
      </div>    
    );
  }
}

export default ForgotPasswordChangePassword
