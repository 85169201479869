var xkcd = require('random-words');
var lodash = require('lodash');

/**
 * Get a list of words from a specific dictionary with specified num, min and max
 * @param wordOptions
 * @param dictionary
 * @returns {Array}
 */
var getWordsFromDictionary = function (wordOptions, dictionary) {
  var words = [];
  while (words.length < wordOptions.num) {
    var word = dictionary();
    if (wordOptions.min <= word.length && word.length <= wordOptions.max) {
      words.push(word);
    }
  }
  return words;
};

/**
 * Get a list of words from appropriate dictionary
 * @param wordOptions
 * @returns {*}
 */
var getWords = function (wordOptions) {
  return getWordsFromDictionary(wordOptions, xkcd);
};

/**
 * Return at least 1 uppercase or maximum N-1 uppercase array of N words for an array of N words
 * @param words
 * @returns {*}
 */
var capitalizeWords = function (words) {
  var capitalWords = 0;
  for (var i = 0; i < words.length; i++) {
    if ((lodash.random() && capitalWords < words.length - 1) || (i == words.length - 1 && !capitalWords)) {
      words[i] = lodash.upperCase(words[i]);
      capitalWords++;
    }
  }
  return words;
};

/**
 * Get a random number from 1 to MAX where n is the number of digits
 * @param n
 * @returns {number}
 */
var getDigits = function (n) {
  return Math.floor(lodash.random(Math.pow(10, n - 1), Math.pow(10, n) - 1));
};

/**
 * Get a random sequence of n symbols
 * @param n
 * @param symbols
 * @returns {*}
 */
var getSymbols = function (n, symbols) {
  for (var i = 0, padding = ''; i < n; i++) {
    let symbol = symbols.charAt(lodash.random(0, symbols.length - 1));
    while (symbol == ":" || symbol == "£" || symbol == "|") {
      symbol = symbols.charAt(lodash.random(0, symbols.length - 1));
    }
    padding += symbol
  }
  // return null if empty to avoid later js transform of 0 + '' to '0'.
  return padding ? padding : null;
};

/**
 * Calculate entropy of password
 * http://search.cpan.org/~bartb/Crypt-HSXKPasswd-v3.6/lib/Crypt/HSXKPasswd.pm
 * @param pass
 * @param blind
 * @returns {number}
 */
var calcEntropy = function (pass, blind) {
  var range = blind ? new Array(72).join() : lodash.uniq(pass);
  return Math.floor(Math.log2(range.length) * pass.length);
};

/**
 * Rate an entropy
 * http://rumkin.com/tools/password/passchk.php
 * @param entropy
 * @returns {{min, max, rate, comment}|*}
 */
var rateEntropy = function (entropy) {
  var ratings = [
    {min:   0, max:   27, rate: 'very weak',   comment: 'might keep out family members'},
    {min:  28, max:   35, rate: 'weak',        comment: 'should keep out most people, often good for desktop login'},
    {min:  36, max:   59, rate: 'reasonable',  comment: 'fairly secure passwords for network and company passwords'},
    {min:  60, max:  127, rate: 'strong',      comment: 'can be good for guarding financial information'},
    {min: 128, max: 1024, rate: 'very strong', comment: 'often overkill'}
  ];
  for (var i = 0; i < ratings.length; i++) {
    var rating = ratings[i];
    if (rating.min <= entropy && entropy <= rating.max) return rating;
  }
};

/**
 * MAIN: password generate
 * @param options
 * @returns {{pass, entropy: number, blindEntropy: number, rating: ({min, max, rate, comment}|*)}}
 */
var generate = function (options) {
  var defaultOptions = {
    words: {
      num: 4, // number of words to generate
      min: 4, // minimum length of each word
      max: 8 // maximum length of each word
    },
    separator: '-', // how to join words
    seperatorOptions: {
      simplePw: true,
    },
    paddingDigits: { // how many digits to add before and after the pass
      before: 0,
      after: 1
    },
    paddingSymbols: { // how many symbols to add before and after the pass
      symbols: '!@#$%^&*()', // which symbols
      before: 0,
      after: 1
    }
  };
  options = lodash.extend({}, defaultOptions, options);

  var words = getWords(options.words);
  words = capitalizeWords(words);
  var pass = "";

  if (options.seperatorOptions.simplePw) {
    pass = "";
    for (var i=0; i<words.length; i++) {
      
      if ((i + 1) == words.length) {
        // Don't add a seperate onto the end
        seperator = "";
      } else if (i == 0) {
        seperator = getSymbols(1, options.paddingSymbols.symbols);
      } else {
        seperator = getDigits(1);
      }

      pass += words[i];
      pass += seperator;
    }
  }  


  if (!options.seperatorOptions.simplePw) {
    var padding =
      getSymbols(options.paddingSymbols.before, options.paddingSymbols.symbols) +
      getDigits(options.paddingDigits.before);
    if (padding) words.unshift(padding);

    padding =
      getDigits(options.paddingDigits.after) +
      getSymbols(options.paddingSymbols.after, options.paddingSymbols.symbols);
    if (padding) words.push(padding);

    
    pass = words.join(options.separator);
  }



  var entropy = calcEntropy(pass);
  var blindEntropy = calcEntropy(pass, true);

  return {
    pass: pass,
    entropy: entropy,
    blindEntropy: blindEntropy,
    rating: rateEntropy(entropy)
  };
};

module.exports = generate;
