import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import PrivacyPolicy from '../misc/PrivacyPolicy';

class PrivacyPolicySession extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div className="col-sm-12">
        <div className="row">
          <div className="col-md-1 col-sm-12 portal-logo">
            <Link to='/'><img src={window.gon.logo_url} /></Link>
          </div>
          <div className="col-md-11 col-sm-12">
            <div className="portal-links">
              <Link to='/'><i className="fa fa-home"></i></Link>
            </div>
          </div>
        </div>
        <PrivacyPolicy />
      </div>
    );
 
    
  }
}

export default PrivacyPolicySession
