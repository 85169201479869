import React from "react";
import PropTypes from "prop-types";
import { HashRouter, Route, Switch, Link } from "react-router-dom";

import Login from './session/Login';
import MFA from './session/Mfa';
import ForgotPassword from './session/ForgotPassword';
import ForgotPasswordResetWithToken from './session/forgotpassword/ResetWithToken';
import ForcePWChange from './session/ForcePWChange';
import PrivacyPolicy from './session/PrivacyPolicy';

import Error404 from './errors/Error404';
import Error500 from './errors/Error500';

class Session extends React.Component {
  render() {

    return(
      <HashRouter>
        <div className="container">
          <div className="row">
            <Error500>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/mfa" component={MFA} />
                <Route exact path="/reset" component={ForgotPassword} />
                <Route exact path="/reset/confirm/:token" 
                       component={ForgotPasswordResetWithToken} />
                <Route exact path="/forcepwchange" component={ForcePWChange} />
                <Route exact path="/privacypolicy" component={PrivacyPolicy} />

                <Route component={Login} />
              </Switch>
            </Error500>
          </div>
        </div>            
      </HashRouter>
    );
  }
}

export default Session

