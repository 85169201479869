import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import Loading from '../../misc/Loading.jsx';
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import NagRenderProgress from '../nagcomponents/RenderProgress';
import PrivacyPolicyFormText from '../../misc/PrivacyPolicyFormText';


class ChallengeNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      error_message: '',
      questions: [],
      selected_questions:{},
      selected_questions_options: {},
      answers: {},
      pw_togggles: {},      
      validation_errors: {},
      loading: true,
      typingTimeout: 0,
      recommend_setup_mfa: false,
      force_mfa_setup: false,
      ldap_chal_enabled: false,
      recommend_setup_ldap_chal: false,
      force_setup_ldap_chal: false,
      next_screen: '',
      next_screen_remove_nag: false
    }

    if (typeof props.nag_options !== 'undefined') {

      if (
        (
          props.nag_options['ldap_chal_enabled'] || 
          props.nag_options['ldap_chap_show_alt']
        ) && !props.nag_options['ldap_chal_completed']
      ) {
        this.state['force_setup_ldap_chal'] = true;
      } else if (!props.nag_options['mfa_completed']) {
        this.state['force_mfa_setup'] = true;
      }

      if (
        props.nag_options['mfa_completed'] && 
        (
          (
            !props.nag_options['ldap_chal_enabled'] && 
            !props.nag_options['ldap_chap_show_alt']
          ) || 
          props.nag_options['ldap_chal_completed']
        )
      ) {
        this.state['last_nag_setup'] = true;
      }
    }


    this.onResponseSelect = this.onResponseSelect.bind(this);
    this.onAnswerChange = this.onAnswerChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePwView = this.togglePwView.bind(this);
    this.continue_next = this.continue_next.bind(this);
    this.cancel_setup = this.cancel_setup.bind(this);

  }

  onResponseSelect(box_id, event) {
    let questions = this.state.questions;
    let selected_questions = this.state.selected_questions;
    let selected_questions_options = this.state.selected_questions_options;
    selected_questions[box_id] = event.target.value;

    for (let i=0; i < questions.length; i++) {
      let question = questions[i];

      if (question.text == event.target.value) {
        selected_questions_options[box_id] = question;
      }
    }

    this.setState({
      selected_questions: selected_questions,
      selected_questions_options: selected_questions_options
    });
  }

  onAnswerChange(box_id, event) {
    const self=this;    
    let value = event.target.value;
    let answers = this.state.answers;

    if (box_id && box_id in this.state.selected_questions) {
      if (this.state.selected_questions[box_id] != '' && this.state.selected_questions[box_id] != null) {
        answers[box_id] = value;
        
        if (this.state.typingTimeout) {
          clearTimeout(this.state.typingTimeout);
        }

        this.setState({
          answers: answers,
          typingTimeout: setTimeout(function() {
            self.validateAgainstWordlist(value, box_id);
          }, 1500)
        });
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    this.setState({
      loading: true
    });

    let formData = new FormData();

    formData.append('questions[]', this.state.selected_questions['question-1']);
    formData.append('questions[]', this.state.selected_questions['question-2']);
    formData.append('questions[]', this.state.selected_questions['question-3']);
    formData.append('answers[]', this.state.answers['question-1']);
    formData.append('answers[]', this.state.answers['question-2']);
    formData.append('answers[]', this.state.answers['question-3']);
    formData.append('authenticity_token', csrf_token)

    try {
      let result = await fetch('/challengeresponse.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {

        if (data.data.recommend_setup_ldap_chal) {
          if (data.data.force_setup_ldap_chal || this.state.force_setup_ldap_chal) {
            this.setState({
              loading: false,
              next_screen: '/myaccount/personal_challenges'
            });          
          } else {
            this.setState({
              loading: false,
              recommend_setup_ldap_chal: true
            });       
          }
        } else if (data.data.recommend_setup_mfa) {
          if (data.data.force_setup_mfa || this.state.force_mfa_setup) {
            this.setState({
              loading: false,
              next_screen: '/mfa'
            });            
          } else {
            this.setState({
              loading: false,
              recommend_setup_mfa: true
            });
          }
        } else if (this.state.last_nag_setup) {
          this.setState({
            loading: false,
            next_screen: '/',
            next_screen_remove_nag: true
          });
        } else {
          this.setState({
            loading: false,
            next_screen: '/challengeresponse',
            next_screen_remove_nag: false
          });
        }
      } else {
        this.setState({ error_message: data.message, loading: false });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  continue_next(event) {
    event.preventDefault();

    let nag_redirect = false;

    if (this.state.next_screen_remove_nag) {
      nag_redirect = this.props.remove_nag();
    }


    if (!nag_redirect) {
      this.props.history.push(this.state.next_screen);
    }
  }

  async cancel_setup(event) {
    event.preventDefault();

    try {
      let result = await fetch('/challengeresponse.json', {credentials: 'same-origin'});

      result = await fetchErrorHandler(result);
      const data = await result.json();
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }
      if (data.status == "ok") {
        if (data.data.questions.length == 0) {
          this.props.history.push("/");
        } else {
          this.props.history.push("/challengeresponse");
        }
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }    
  }

  async componentDidMount() {
    if (typeof this.props.nag_options !== 'undefined' && this.props.nag_options.challenges_completed) {
      this.props.history.push("/");
    }  

    try {
      let result = await fetch('/challengeresponse/unused.json', {credentials: 'same-origin'});

      result = fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        this.setState({
          loading: false,
          questions: data.data.questions
        })
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }  

  getUnusedQuestions(box_id=null) {
    let questions = this.state.questions;
    let used_questions = this.state.selected_questions;
    let unused_questions = [];
    let selected_question = null;

    if (box_id && box_id in this.state.selected_questions) {
      if (this.state.selected_questions[box_id] != '' && this.state.selected_questions[box_id] != null) {
        selected_question = this.state.selected_questions[box_id];
      }
    }

    for (let i=0; i < questions.length; i++) {
      let question = questions[i];
      if (selected_question === question.text) {
        unused_questions.push(question);
      } else if (Object.values(used_questions).indexOf(question.text) == -1) {
        unused_questions.push(question);
      }
    }


    return unused_questions;
  }

  togglePwView(question_id, event) {
    event.preventDefault();

    let pw_togggles = this.state.pw_togggles;
    if (
      typeof pw_togggles[question_id] === 'undefined' || 
      !pw_togggles[question_id]
    ) {
      pw_togggles[question_id] = true;
    } else {
      pw_togggles[question_id] = false;
    }

    this.setState({
      pw_togggles: pw_togggles
    });
  }


  renderNagProgress() {
    return(
      <React.Fragment>
        <NagRenderProgress nag_options={this.props.nag_options} current="challenges" />
      </React.Fragment>
    )
  }

  renderSkipButton() {
    if (!this.props.nag_options.allow_skip_challenge || !this.props.nag_options.allow_skip) {
      return;
    }

    return (
      <React.Fragment>
        <button className="btn btn-primary" 
                onClick={(e) => this.props.nagSkipIndividal(this,"challenges", e)}
                type="button"
                >
                Skip (remind me later)
        </button>&nbsp;
      </React.Fragment>
    )
  }  

  renderError() {
    if (this.state.error_message) {
      return(
        <div className="alert alert-danger">{this.state.error_message}</div>
      );
    }
    return;
  }

  validateAgainstWordlist(answer, box_id)  {
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('text', answer);
    formData.append('authenticity_token', csrf_token)

    try {
      fetch('/challengeresponse/wordlist_check.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      }).then(fetchErrorHandler)
      .then(result => result.json())
      .then(data => {
        if (typeof this.props.resetInactivityTimer !== 'undefined') {
          this.props.resetInactivityTimer();
        }
                
        if (data.status == "ok") {
          let validation_errors = this.state.validation_errors;

          if (!data.data.result) {
            // Answer has been found in word list
            validation_errors[box_id] =  "Your response is too commonly used.";
          } else {
            validation_errors[box_id] = null;
          }

          this.setState({validation_errors: validation_errors});
        } else {
          this.setState({ error_message: data.message });
        }
      });
    } catch (error) {
    }

    return;
  }

  renderChallengeBoxValidation(box_id) {
    if (box_id in this.state.validation_errors && this.state.validation_errors[box_id]) {
      return (
        <div className="invalid-validation">{this.state.validation_errors[box_id]}</div>
      )
    }      

    if (box_id in this.state.selected_questions) {    
      if (this.state.selected_questions[box_id] != '' && this.state.selected_questions[box_id] != null) {
        if (box_id in this.state.selected_questions_options && box_id in this.state.answers) {
          let answer = this.state.answers[box_id];
          let options = this.state.selected_questions_options[box_id];
          let error = null;

          if (answer.trim().length < options.minLength) {
            error = "Your answer should be longer than " + options.minLength + " characters";
          } else if (answer.length > options.maxLength) {
            error = "Your answer should be shorter than " + options.maxLength + " characters";
          }

          if (error) {
            return (
              <div className="invalid-validation">{error}</div>
            );
          }
        }
      }
    }

    return;
  }

  renderChallengeBox(box_id) {
    let select_id = box_id + "_select";
    let input_id = box_id + "_input";

    let disabled = true;
    let selected_value = "";
    let answer_value = "";

    if (box_id in this.state.selected_questions) {
      selected_value = this.state.selected_questions[box_id];

      if (this.state.selected_questions[box_id] != '' && this.state.selected_questions[box_id] != null) {
        disabled = false;
      }
    }

    if (box_id in this.state.answers) {
      answer_value = this.state.answers[box_id];
    }

    let toggle_pw_classes = "input-group-text fa";
    let toggle_pw_box_class = "password";

    if (
      typeof this.state.pw_togggles[box_id] !== 'undefined' &&
      this.state.pw_togggles[box_id]
    ) {
      toggle_pw_classes += " fa-eye";
      toggle_pw_box_class = "text";
    } else {
      toggle_pw_classes += " fa-eye-slash";
    }    


    return (
      <div className="form-group challengeresponse-group">
        <select className="form-control" id={select_id} 
                value={selected_value} onChange={(e) => this.onResponseSelect(box_id, e)}>
          <option value="">--- Please select a question item from the list ---</option>

          {this.getUnusedQuestions(box_id).map(function(question, i) {
            return (
              <option value={question['text']} key={i}>{question['text']}</option>
            );
          })}
        </select>

        <div className="input-group">
          <input type={toggle_pw_box_class} value={answer_value} className="form-control" id={input_id} disabled={disabled}
                 onChange={(e) => this.onAnswerChange(box_id, e)}/>
          <div className="input-group-append">
            <i className={toggle_pw_classes}
               onClick={(e) => this.togglePwView(box_id, e)}></i>
          </div>
        </div>

        {this.renderChallengeBoxValidation(box_id)}
      </div>
    );
  }

  renderForm() {
    return (
      <div>
          {this.renderChallengeBox("question-1")}
          {this.renderChallengeBox("question-2")}
          {this.renderChallengeBox("question-3")}
      </div>
    )
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }
    
    var self=this;

    if (this.state.loading) {
      return (
        <Loading loading={this.state.loading} />
      );
    }

    if (this.state.next_screen) {
      if (typeof this.props.nag_options !== 'undefined') {
        return (
         <div>
          <h1>Account setup</h1>
          {this.renderNagProgress()}

          <div className="nag-padded">
            <h3>Setup challenges</h3>
            <div className="alert alert-success">
              Your challenge response questions/answers have been setup.
            </div>

             <button className="btn btn-primary"
                     onClick={this.continue_next}>
               Continue
             </button>          
            </div>
          </div>            
        )
      }


      return (
         <div>
          <h1>Setup challenges</h1>
          <div className="alert alert-success">
            Your challenge response questions/answers have been setup.
          </div>

           <button className="btn btn-primary"
                   onClick={this.continue_next}>
              Continue
           </button>          
        </div>  
      );
    }

    if (this.state.recommend_setup_mfa) {
      return (
        <div>
          <h1>Setup challenges</h1>
          <div className="alert alert-success">
            Your challenge response questions/answers have been setup.
          </div>

          <p>
            We notice you haven't yet setup your multi factor authentication. If you forget your password you can access 
            your account by entering your mutli factor code. If you would like to do that now then click on the button 
            below.
          </p>

          <Link to='/mfa' className="btn btn-primary">Setup multi factor authentication</Link>          
        </div>
      )
    }

    if (this.state.recommend_setup_ldap_chal) {
      return (
        <div>
          <h1>Setup challenges</h1>
          <div className="alert alert-success">
            Your challenge response questions/answers have been setup.
          </div>

          <p>
            We notice you haven't yet setup your personal verification 
            challenges, if you call the service desk they may use this 
            information to verify your identity. If you would like to do that 
            now then click on the button below

          </p>

          <Link to='/myaccount/personal_challenges' className="btn btn-primary">
            Setup personal verification challenges
          </Link>          
        </div>
      )
    }


    if (typeof this.props.nag_options !== 'undefined') {
      let nag_text = "We notice that you haven't yet setup your challenge and "+
      "response questions, if you would like to do that now then please follow "+ 
      "the instructions below, or click skip if you'd like to complete them later.";

      if (
        !this.props.nag_options.allow_skip_challenge || 
        !this.props.nag_options.allow_skip
      ) {
        let nag_text = "We notice that you haven't yet setup your challenge "+
        "and response questions, please follow the instructions below.";
      }

      return (
        <div>
          <h1>Account status</h1>
          {this.renderNagProgress()}
          <form onSubmit={this.handleSubmit}>
            <div className="nag-padded">
              <p>
                <strong>
                  {nag_text}
                </strong>
              </p>

              <h3>Setup challenges</h3>

              {this.renderError()}
              <p>
                 Please choose your questions and supply answers below that will be used. 
                 Because the answers to these questions give access your account, be sure  
                 to supply answers that are not easy for others to guess or discover 
                 and ones that are not the same as each other.
              </p>

              <PrivacyPolicyFormText />
              {this.renderForm()}      
            </div>

            <div className="pull-right">
              {this.renderSkipButton()}
              <input type="submit" className="btn btn-primary" value="Submit answers" />
            </div>
          </form>
        </div>
      )
    }


    return (
      <div>
        <h1>Setup challenges</h1>

        <p>If you forget your pasword you can access your account by answering these questions instead.</p>
        <p>
           Please choose your questions and supply answers below that will be used. 
           Because the answers to these questions give access your account, be sure  
           to supply answers that are not easy for others to guess or discover 
           and ones that are not the same as each other.
        </p>
        {this.renderError()}

        <form onSubmit={this.handleSubmit}>
          <PrivacyPolicyFormText />

          {this.renderForm()}
          <input type="submit" className="btn btn-primary" value="Submit answers" />&nbsp;
          <button className="btn btn-primary" onClick={this.cancel_setup}>Cancel</button>
        </form>

      </div>
    );
  }
}

export default ChallengeNew;
