import React from "react"
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import zxcvbn from 'zxcvbn';
import xkcdPass from '../misc/xkcd-pass-plus';
import Modal from 'react-bootstrap/Modal';


class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',      
      password_score: -1,
      password_policy_checks: {
        characters: false,
        lower: false,
        upper: false,
        num: false,
        symbol: false,
        disallowed_chars: true
      },
      password_policy_text: this.props.password_policy_text,
      password_policy: this.props.password_policy,
      showGenerateModal: false,
      generatedPassword: '',
      pw_togggles: {}
    }

    this.new_password_input = React.createRef();
    this.confirm_password_input = React.createRef();
    this.validatePassword = this.validatePassword.bind(this);

    this.handleChangePwSubmit = this.handleChangePwSubmit.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.handleGenerateModalOpen = this.handleGenerateModalOpen.bind(this);
    this.handleGenerateModalClose = this.handleGenerateModalClose.bind(this);
    this.handleGenerateUsePw = this.handleGenerateUsePw.bind(this);
    this.handleGenerateNewPassword = this.handleGenerateNewPassword.bind(this)   
    this.handleGenPwChange = this.handleGenPwChange.bind(this); 
    this.togglePwView = this.togglePwView.bind(this);
  }

  handleChangePwSubmit(event) {
    event.preventDefault();
    if (this.new_password_input && this.new_password_input.current) {
      this.props.changePasswordSubmit(
        this.new_password_input.current.value, 
        this.confirm_password_input.current.value,
        this.errorCallback.bind(this)
      );
    }
  }

  handleGenPwChange(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;

    this.setState({
      generatedPassword : value
    });
  }
  errorCallback() {
    this.setState({
      password_policy_checks: {
        characters: false,
        lower: false,
        upper: false,
        num: false,
        symbol: false        
      },
      password_score: -1           
    });

    //  We don't update the password dom from state for security reasons
    //  so we need to empty it via a reference
    if (this.new_password_input && this.new_password_input.current) {
      this.new_password_input.current.value = "";
    }
    if (this.confirm_password_input && this.confirm_password_input.current) {
      this.confirm_password_input.current.value = "";    
    }
  }

  validatePassword(event, calledRemote=false) {
    let value = null;
    if (calledRemote) {
      value = this.new_password_input.current.value;
    } else {
      value = event.target.value;
    }

    if (value.length == 0) {
      let password_policy_checks = {
        characters: false,
        lower: false,
        upper: false,
        num: false,
        symbol: false,
        disallowed_chars: true
      }

      this.setState({
        password_score: -1,
        password_policy_checks: password_policy_checks
      });
    } else {
      let password_policy_checks = {
        characters: false,
        lower: false,
        upper: false,
        num: false,
        symbol: false,
        disallowed_chars: true
      }

      if (value.length >=  this.state.password_policy['chai.pwrule.length.min']) {
        password_policy_checks.characters = true;
      }

      let alpha_count = (value.match(/[a-zA-Z]/g) || []).length
      let upper_count = (value.match(/[A-Z]/g) || []).length
      let lower_count = (value.match(/[a-z]/g) || []).length
      let num_count = (value.match(/[0-9]/g) || []).length
      let nonalpha_count = (value.length - alpha_count) - num_count
      let disallowed_count = (value.match(/[\:\|\u00A3]/g) || []).length

      if (upper_count >= 1) {
        password_policy_checks.upper = true;
      }

      if (lower_count  >= 1) {
        password_policy_checks.lower = true;
      }

      if (num_count  >=  1) {
        password_policy_checks.num = true;
      }

      if (nonalpha_count >= 1) {
        password_policy_checks.symbol = true;
      }

      if (nonalpha_count >= 1) {
        password_policy_checks.symbol = true;
      }

      if (disallowed_count > 0) {
        password_policy_checks.disallowed_chars = false;
      }


      this.setState({
        password_score: zxcvbn(value).score,
        password_policy_checks: password_policy_checks
      });
    }
  }  

  generatePassword() {
    var defaultOptions = {
      words: {
        dictionary: 'mixed', // xkcd (2k, most memorable) or letterpress (270k) or mixed
        num: 3, // number of words to generate
        min: 4, // minimum length of each word
        max: 8 // maximum length of each word
      },
      separator: '-', // how to join words
      seperatorOptions: {
        simplePw: true,
      },
      paddingDigits: { // how many digits to add before and after the pass 
        before: 0,
        after: 0
      },
      paddingSymbols: { // how many symbols to add before and after the pass
        symbols: '!@#$%^&*()', // which symbols
        before: 0,
        after: 0
      }
    };

    return xkcdPass(defaultOptions).pass;    
  }   

  renderDisallowedChars(disallowed_class,disallowed_i_class) {
    if (typeof window.gon.show_password_policy_checks_extra !== 'undefined') {
      if (window.gon.show_password_policy_checks_extra != true) {
        return (
          <div></div>
        )
      }
    } else {
      return (
        <div></div>
      )
    }

    return (
      <div className={disallowed_class}>
        <i className={disallowed_i_class}></i> Avoid using : £ |
     </div>      
    );
  }

  renderPasswordStrengthChecks() {
    if (typeof window.gon.show_password_policy_checks !== 'undefined') {
      if (window.gon.show_password_policy_checks != true) {
        return (
          <div></div>
        )
      }
    }

    let min_characters_class = "pw-strength-check";
    let lower_case_class = "pw-strength-check";
    let upper_case_class = "pw-strength-check";
    let number_class = "pw-strength-check";
    let symbol_class = "pw-strength-check";
    let disallowed_class = "pw-strength-check";

    let min_characters_i_class = "fa fa-times";
    let lower_case_i_class = "fa fa-times";
    let upper_case_i_class = "fa fa-times";
    let number_i_class = "fa fa-times";
    let symbol_i_class = "fa fa-times";
    let disallowed_i_class = "fa fa-times";


    if (this.state.password_policy['chai.pwrule.length.min'] != 0) {
      min_characters_class = "pw-strength-check";

      if (this.state.password_policy_checks.characters) {
        min_characters_i_class = "fa fa-check";
        min_characters_class += " valid";
      } else {
        min_characters_class += " invalid";
      }
    }

      lower_case_class = "pw-strength-check ";

      if (this.state.password_policy_checks.lower) {
        lower_case_i_class = "fa fa-check";
        lower_case_class += " valid";
      } else {
        lower_case_class += " invalid";
      }      

      upper_case_class = "pw-strength-check";

      if (this.state.password_policy_checks.upper) {
        upper_case_i_class = "fa fa-check";
        upper_case_class += " valid";
      } else {
        upper_case_class += " invalid";
      }      

      number_class = "pw-strength-check";

      if (this.state.password_policy_checks.num) {
        number_i_class = "fa fa-check";
        number_class += " valid";
      } else {
        number_class += " invalid";
      }      


      symbol_class = "pw-strength-check";

      if (this.state.password_policy_checks.symbol) {
        symbol_i_class = "fa fa-check";
        symbol_class += " valid";
      } else {
        symbol_class += " invalid";
      }   


      disallowed_class = "pw-strength-check";

      if (this.state.password_policy_checks.disallowed_chars) {
        disallowed_i_class = "fa fa-check";
        disallowed_class += " valid";
      } else {
        disallowed_class += " invalid";
      }           

    return (
      <div className="pw-strength-checks">
        <div className={min_characters_class}>
          <i className={min_characters_i_class}></i> {this.state.password_policy['chai.pwrule.length.min']} Characters
        </div>

        <div className={lower_case_class}>
          <i className={lower_case_i_class}></i> Lower case
        </div>

        <div className={upper_case_class}>
          <i className={upper_case_i_class}></i> Upper case
        </div>

        <div className={number_class}>
          <i className={number_i_class}></i> Numbers
        </div>  

        <div className={symbol_class}>
          <i className={symbol_i_class}></i> Symbols
        </div>         

        {this.renderDisallowedChars(disallowed_class,disallowed_i_class)}
                                                             
      </div>      
    );
  }  

  handleGenerateModalOpen(event) {
    event.preventDefault();
    this.setState({
      showGenerateModal: true,
      generatedPassword: this.generatePassword()
    });
  }

  handleGenerateNewPassword(event) {
    event.preventDefault();
    this.setState({
      generatedPassword: this.generatePassword()
    });
  }

  handleGenerateUsePw(event) {
    event.preventDefault();

    this.new_password_input.current.value = this.state.generatedPassword;
    this.validatePassword(null, true);

    this.setState({
      showGenerateModal: false,
      generatedPassword: ''
    });    
  }

  handleGenerateModalClose() {
    this.setState({
      showGenerateModal: false,
      generatedPassword: ''
    });
  }


  togglePwView(form_id, event) {
    event.preventDefault();

    let pw_togggles = this.state.pw_togggles;
    if (
      typeof pw_togggles[form_id] === 'undefined' || 
      !pw_togggles[form_id]
    ) {
      pw_togggles[form_id] = true;
    } else {
      pw_togggles[form_id] = false;
    }

    this.setState({
      pw_togggles: pw_togggles
    });
  }

  render() {
    var self = this;

    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    let strength_bar_percentage = '0%';
    let strength_bar_classes = "progress-bar progress-bar-animated bg-none";
    let strength_bar_value = "";

    if (this.state.password_score >= 0) 
    {
      if (this.state.password_score == 0) {
        strength_bar_classes = "progress-bar progress-bar-animated bg-danger";
        strength_bar_percentage = "20%";
        strength_bar_value = "Very Weak";             
      }
      if (this.state.password_score == 1) {
        strength_bar_classes = "progress-bar progress-bar-animated bg-danger";          
        strength_bar_percentage = "40%";
        strength_bar_value = "Weak";
      } else if (this.state.password_score == 2) {
        strength_bar_classes = "progress-bar progress-bar-animated bg-warning";
        strength_bar_percentage = "60%";
        strength_bar_value = "Medium";          
      } else if (this.state.password_score == 3) {
        strength_bar_classes = "progress-bar progress-bar-animated bg-success";
        strength_bar_percentage = "80%";
        strength_bar_value = "Strong";          
      } else if (this.state.password_score == 4) {
        strength_bar_classes = "progress-bar progress-bar-animated bg-success";
        strength_bar_percentage = "100%";
        strength_bar_value = "Very Strong";          
      }
    }    


    let toggle_pw_classes = "input-group-text fa";
    let toggle_pw_box_class = "password";
    let toggle_pw_confirm_classes = "input-group-text fa";
    let toggle_pw_confirm_box_class = "password";

    if (
      typeof this.state.pw_togggles['password'] !== 'undefined' &&
      this.state.pw_togggles['password']
    ) {
      toggle_pw_classes += " fa-eye";
      toggle_pw_box_class = "text";
    } else {
      toggle_pw_classes += " fa-eye-slash";
    }

    if (
      typeof this.state.pw_togggles['confirm_password'] !== 'undefined' &&
      this.state.pw_togggles['confirm_password']
    ) {
      toggle_pw_confirm_classes += " fa-eye";
      toggle_pw_confirm_box_class = "text";
    } else {
      toggle_pw_confirm_classes += " fa-eye-slash";
    }    

    let cancel_button = <div></div>;

    if (this.props.show_cancel_button) {
      cancel_button = <Link to={this.props.cancel_link}
                            className="btn btn-primary">Cancel</Link>
    }
    
    return (
      <div>
        <p>
          Please enter your new password below, confirm it and then click the Change Password button. 
        </p>

        <ul>
          <li>Note passwords are case sensitive</li>
          <li>Choose a strong password. Use the generate password button to suggest one based on the three random words with added numbers and symbols recipe.</li>
          <li>If you must write it down, be sure to keep it in a safe place.</li>
        </ul>

        <p>Ideally your password should:</p>
        <ul>
          {this.state.password_policy_text.map(function(policy, i) {
            if (policy.length > 0) {
              return (
                <li key={i}>{policy}</li>
              );
            }
          })}
        </ul>

        <form onSubmit={this.handleChangePwSubmit}>
          <div className="form-group">        
            <label>New Password <a href="javascript:void(0);" onClick={this.handleGenerateModalOpen}>(generate password)</a></label>

            {this.renderPasswordStrengthChecks()}

            <div className="input-group">
              <input type={toggle_pw_box_class} id="new_password" className="form-control" 
                   onChange={this.validatePassword} ref={this.new_password_input}
                   autoComplete="new-password" />
              <div className="input-group-append">
                <i className={toggle_pw_classes}
                   onClick={(e) => self.togglePwView('password', e)}></i>
              </div>                   
            </div>

            <div className="progress strength-meter">
              <div className={strength_bar_classes} style={{width: strength_bar_percentage}}>{strength_bar_value}</div>
            </div>                     
          </div>          

          <div className="form-group">
            <label>Confirm Password</label>
            <div className="input-group">
              <input type={toggle_pw_confirm_box_class} id="confirm_password" className="form-control" 
                     ref={this.confirm_password_input}
                     autoComplete="new-password" />
              <div className="input-group-append">
                <i className={toggle_pw_confirm_classes}
                   onClick={(e) => self.togglePwView('confirm_password', e)}></i>
              </div>              
            </div>
          </div>      

          <input type="submit" className="btn btn-primary" value="Change password" />&nbsp;        
          {cancel_button} 
        </form>

        <Modal show={this.state.showGenerateModal} onHide={this.handleGenerateModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Generate password</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <label>Generated password <a href="javascript:void(0);" onClick={this.handleGenerateNewPassword}>(generate again)</a></label>
              <input type="text" id="generated_password" className="form-control" 
                     value={this.state.generatedPassword}
                     onChange={this.handleGenPwChange}
                      />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.handleGenerateModalClose}>Close</button>
            <button className="btn btn-danger" onClick={this.handleGenerateUsePw}>Use password</button>
          </Modal.Footer>
        </Modal>                         
      </div>       
    );
  }
}

export default ChangePasswordForm
