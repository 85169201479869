import React from "react"
import PropTypes from "prop-types";
import {Link} from "react-router-dom"

import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import Loading from '../../misc/Loading.jsx';

import RenderProgress from './RenderProgress';
import RenderError from '../../misc/RenderError';

class ForgotPasswordCheckUsername extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      caughtError: '',      
      username: '',
      error_message: '',
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleUsernameCheck = this.handleUsernameCheck.bind(this);    
    this.onUsernameSubmit = this.onUsernameSubmit.bind(this);    
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyRecaptchaCallback = this.verifyRecaptchaCallback.bind(this);
  }


  onUsernameSubmit(event) {
    event.preventDefault();

    if (this.reCaptchaRef && typeof window.gon.recaptcha_site_key !== 'undefined' && window.gon.recaptcha_site_key != null && window.gon.recaptcha_site_key.length > 0) {
      this.reCaptchaRef.reset();
      this.reCaptchaRef.execute();
    } else if (typeof window.gon.recaptcha_site_key === 'undefined' || window.gon.recaptcha_site_key == null || window.gon.recaptcha_site_key.length < 1) {
      this.handleUsernameCheck(null);
    }
  }

  async handleUsernameCheck(recaptchaToken) {

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('username', this.state.username);
    formData.append('g-recaptcha-response', recaptchaToken);
    formData.append('authenticity_token', csrf_token)

    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/session/forgotpassword/check_username.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (data.status == "ok") {
        if (data.data.challenges.length < 1 || data.data.challenges[0] == null) {
          this.props.childStateSet({
            loading: false,
            show_help_desk_details: true
          });
        } else {
          this.setState({
            loading :false,
            error_message: ''
          });

          this.props.childStateSet({
            username: this.state.username,
            step: 2,
            challenges: data.data.challenges,
          });
        }
      } else {
        this.setState({
          loading: false,
          username: '',
          error_message: data.message
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.id 

    this.setState({
      [name] : value
    });
  }  

  renderRecaptcha() {
    if (typeof window.gon.recaptcha_site_key !== 'undefined' && window.gon.recaptcha_site_key != null && window.gon.recaptcha_site_key.length > 0) {
      return (
        <ReCaptcha
            ref={(el) => {this.reCaptchaRef = el;}}
            size="invisible"
            render="explicit"
            sitekey={window.gon.recaptcha_site_key}
            onloadCallback={this.onLoadRecaptcha}
            verifyCallback={this.verifyRecaptchaCallback}
        />    
      )
    }
  }

  componentDidMount() {
    loadReCaptcha();

    if (this.reCaptchaRef) {
      this.reCaptchaRef.reset();
    }
  }

  onLoadRecaptcha() {
    if (this.reCaptchaRef) {
      this.reCaptchaRef.reset();
    }    
  }

  verifyRecaptchaCallback(recaptchaToken) {
    this.handleUsernameCheck(recaptchaToken);
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 portal">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    let privacypolicy = "";

    if (gon.show_privacy_policy && !window.location.href.includes('privacypolicy')) {
      privacypolicy = (
        <p className="portal_footer">
          <Link to="/privacypolicy">Privacy Policy</Link>
        </p>
      );
    }        


    let top_class = "col-sm-12";

    if (typeof window.gon !== 'undefined' && typeof window.gon.sso !== 'undefined' && window.gon.sso == true) {
      top_class = top_class + " in-modal"
    }        

    return (
      <div className={top_class}>
        <div className="row">
          <div className="col-md-1 col-sm-12 portal-logo">
            <Link to='/'><img src={window.gon.logo_url} /></Link>
          </div>
          <div className="col-md-11 col-sm-12">
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <h1>Forgotten password</h1>

            <RenderProgress step={1} />

            <p>Please enter your username or email address below</p>

            <RenderError error_message={this.state.error_message} />

            <form onSubmit={this.onUsernameSubmit}>
              <div className="form-group">
                <input type="text" id="username" placeholder="Username" className="form-control"
                      value={this.state.username} onChange={this.handleChange}/>
              </div>

              {this.renderRecaptcha()}

              <input type="submit" className="btn btn-primary" value="Submit" />
              &nbsp;
              <Link to="/" className="btn btn-primary">Cancel</Link>
            </form>            
          </div>
        </div>  
        <div className="row">
          <div className="col-sm-12 footer">
              {privacypolicy}
            </div>
        </div>          
      </div>
    )
  }
}

export default ForgotPasswordCheckUsername
