import React from "react";
import PropTypes from "prop-types";

class PrivacyPolicyFormText extends React.Component {
  render() {
    if (
      typeof gon === 'undefined' || 
      typeof gon.show_privacy_policy === 'undefined' ||
      gon.show_privacy_policy == false ||
      typeof gon.privacy_policy_form_message == 'undefined' ||
      gon.privacy_policy_form_message.length < 1
    ) {
      return (<React.Fragment></React.Fragment>);
    }

    let privacy_text =  gon.privacy_policy_form_message;
    let priv_link = privacy_text.match(/%%(.*)%%/);

    if (priv_link.length > 1) {
      let link_txt = priv_link[1];

      privacy_text = privacy_text.replace(
        /(%%.*%%)/, 
        "<a href=\"/#/privacypolicy\" target=\"_blank\">" + link_txt + "</a>"
      );
    }

    return (
      <p dangerouslySetInnerHTML={{
        __html: privacy_text
       }}>
        
      </p>
    )
  }
}


export default PrivacyPolicyFormText;
