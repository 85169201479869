import React from "react"
import PropTypes from "prop-types";
import {Link} from "react-router-dom"

import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import Loading from '../../misc/Loading.jsx';

import RenderProgress from './RenderProgress';
import RenderError from '../../misc/RenderError';
import RenderSuccess from '../../misc/RenderSuccess';

class ForgotPasswordCheckMFA extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      caughtError: '',      
      error_message: '',
      success_message: '',
      failed_attempts: 0
    }


    this.token_input = React.createRef();
    this.handleSendCode = this.handleSendCode.bind(this);
    this.handleMfaCheck = this.handleMfaCheck.bind(this);    
  }


  async handleSendCode(token) {
    this.token_input.current.focus();
    
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('mfa_serial', token.serial);
    formData.append('authenticity_token', csrf_token);
    try {
      let result = await fetch('/session/mfa_sendcode.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (data.status == "ok") {
        this.setState({
          error_message: '',          
          success_message: data.data.message
        });
      } else {
        this.setState({
          error_message: data.message
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async handleMfaCheck(event) {
    event.preventDefault();

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    this.setState({
      loading: true
    });

    let formData = new FormData();
    formData.append('mfa_token', this.token_input.current.value );
    formData.append('authenticity_token', csrf_token)

   try {
      let result = fetch('/session/mfa_login.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });
      
      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (data.status == "ok") {
        this.props.childStateSet({
          step: 4,
          password_policy_text: data.data.password_policy[0],
          password_policy: data.data.password_policy[1],        
        });
      } else {
        let failed_attempts = this.state.failed_attempts;
        failed_attempts++;

        if (failed_attempts == 3) {
          this.props.childStateSet({
            show_help_desk_details: true
          });
        } else {
          this.setState({
            success_message: '',
            error_message: data.message,
            loading: false,
            failed_attempts: failed_attempts
          });
        }
      }  
    } catch (error) {
      this.setState({caughtError: error});
    }
  }  
  

  renderToken(token,i) {
    if (token.type == 'sms') {
      token.type = 'SMS';
    }

    
    return(
      <tr key={i}>
        <td>{token.serial}</td>
        <td>{token.description}</td>
        <td>{token.type}</td>
        <td>{this.renderTokenButton(token)}</td>
      </tr>
    )
  }


  renderTokenButton(token) {
    if (token.type == 'email' || token.type == 'sms' || token.type == 'SMS') {
      return (
        <button className="btn btn-success" onClick={(e) => this.handleSendCode(token)}>Send code</button>
      )
    }

    return;
  }


  render() {
    var self = this;

    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 portal">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    let privacypolicy = "";

    if (gon.show_privacy_policy && !window.location.href.includes('privacypolicy')) {
      privacypolicy = (
        <p className="portal_footer">
          <Link to="/privacypolicy">Privacy Policy</Link>
        </p>
      );
    }         

    let top_class = "col-sm-12";

    if (typeof window.gon !== 'undefined' && typeof window.gon.sso !== 'undefined' && window.gon.sso == true) {
      top_class = top_class + " in-modal"
    }        

    
    return (
      <div className={top_class}>
        <div className="row">
          <div className="col-md-1 col-sm-12 portal-logo">
            <Link to='/'><img src={window.gon.logo_url} /></Link>
          </div>
          <div className="col-md-11 col-sm-12">
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <h1>Forgotten password</h1>        

            <RenderProgress step={3} />

            <p>If you have forgotten your password, follow the prompts to reset your password</p>

            <RenderError error_message={this.state.error_message} />
            <RenderSuccess success_message={this.state.success_message} />

            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Token ID</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.props.parentState.tokens.map(function(token, i) {
                  return self.renderToken(token, i);
                })}
              </tbody>
            </table>

            <p>
              To login, please enter the code from one of these devices. If the token type is SMS you will need to click 
              "Send code" above to recieve the code.
            </p>

            <form onSubmit={this.handleMfaCheck}>
              <div className="form-group">
                <input type="password" id="token" placeholder="Enter multifactor code" className="form-control"
                        ref={this.token_input} autoFocus={true}/>
              </div>

              <input type="submit" className="btn btn-primary" value="Check code" />
            </form>
          </div>
        </div>  
        <div className="row">
          <div className="col-sm-12 footer">
            {privacypolicy}
          </div>
        </div>                  
      </div>            
    );
  }
}

export default ForgotPasswordCheckMFA
