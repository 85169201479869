import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import zxcvbn from 'zxcvbn';
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import Loading from '../../misc/Loading.jsx';
import RenderError from '../../misc/RenderError';
import ChangePasswordForm from '../../misc/ChangePasswordForm';


class ForgotPasswordResetWithToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      caughtError: '',
      error_message: '',
      password_policy_text: [],
      password_policy: {},
      show_sso_close_message: false,
    }


    this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
  }

  async changePasswordSubmit(new_password, confirm_password, errorCallback) {

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));
    let formData = new FormData();
    formData.append('token',  this.props.match.params.token);
    formData.append('new_password', new_password);
    formData.append('confirm_password', confirm_password);
    formData.append('authenticity_token', csrf_token);

    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/session/forgotpassword/reset/confirm', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (data.status == "ok") {
        this.setState({
          loading: false,
          show_sso_close_message: true
        });
        return
      } else {
        this.setState({
          error_message: data.message,
          loading: false,            
        });

        errorCallback();
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async componentDidMount() {
    try {
      let result = await fetch(
        '/session/forgotpassword/reset/confirm?token=' + this.props.match.params.token, 
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (data.status == "ok") {
        this.setState({
          loading: false,
          password_policy_text: data.data.password_policy[0],
          password_policy: data.data.password_policy[1]          
        })
      } else if (data.message == "A valid reset token is required") {
        this.props.history.push("/reset");
        return
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }


  render() {
    let self=this;

    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 portal">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    let privacypolicy = "";

    if (gon.show_privacy_policy && !window.location.href.includes('privacypolicy')) {
      privacypolicy = (
        <p className="portal_footer">
          <Link to="/privacypolicy">Privacy Policy</Link>
        </p>
      );
    }        

    if (this.state.show_sso_close_message) {
      return (
        <div className="col-sm-12">
          <div className="row">
            <div className="col-md-1 col-sm-12 portal-logo">
              <Link to='/'><img src={window.gon.logo_url} /></Link>
            </div>
            <div className="col-md-11 col-sm-12">
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">           
              <div>
                <p>
                  Your password has been successfuly changed. 
                </p>

                <p>
                  <Link to='/' className="btn btn-primary">Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>      
      );
    }


    let top_class = "col-sm-12";

    return(
      <div className={top_class}>
        <div className="row">
          <div className="col-md-1 col-sm-12 portal-logo">
            <Link to='/'><img src={window.gon.logo_url} /></Link>
          </div>
          <div className="col-md-11 col-sm-12">
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">          
            <div>
              <h1>Change password</h1>

                <RenderError error_message={this.state.error_message} />
          
                <ChangePasswordForm 
                   changePasswordSubmit={this.changePasswordSubmit.bind(this)}
                   password_policy_text={this.state.password_policy_text}
                   password_policy={this.state.password_policy} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 footer">
            {privacypolicy}
          </div>
        </div>          
      </div>
    );
  }
}

export default ForgotPasswordResetWithToken
