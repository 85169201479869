import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Error404 from './Error404';
import Error403 from './Error403';

class Error500 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorType: 500
    }
  }

  componentDidCatch(error, info) {
    let error_type = 500;
    if (error.message == "404,NotFound") {
      error_type = 404;
    } else if (error.message == "403,Forbidden") {
      error_type = 403;
    } else if (error.message == "500,You are not authenticated.") {
      window.location.href = '/';
      return;
    }

    this.setState({ hasError: true, errorType: error_type  });
  }


  disableError(event) {
    this.state.hasError = false;
    this.state.error_type = 500;
    this.setState({hasError: false, error_type: 500});
  }


  render() {
    if (this.state.hasError) {
      if (this.state.errorType == 404) {
        return (
          <Error404 disableError={this.disableError.bind(this)}  />
        );
      } else if (this.state.errorType == 403) {
        return (
          <Error403 disableError={this.disableError.bind(this)} />
        );
      } else {
        return (
          <div className="login_box responsive">
            <div className="col-sm-12">
              <div className="logo">
                <img src={window.gon.logo_url} />

                <h1>Internal Server Error </h1>

                <p>An unexpected error has occured.</p>
                <p><Link to='/' onClick={this.disableError.bind(this)}>Click here to go back</Link></p>
              </div>
            </div>
          </div>          
        );
      }
    }

    return this.props.children;
  }
}

export default Error500;
