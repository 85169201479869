import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import Loading from '../../misc/Loading.jsx';
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import RenderError from '../../misc/RenderError';
import NagRenderProgress from '../nagcomponents/RenderProgress';
import PrivacyPolicyFormText from '../../misc/PrivacyPolicyFormText';

class ChallengeNewLdap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      error_message: '',
      questions: [],
      answers: {},
      validation_errors: {},
      loading: true,
      recommend_setup_mfa: false,
      force_mfa_setup: false,
      recommend_setup_challenges: false,
      force_challenge_setup: false,
      last_nag_setup: false,
      next_screen: '',
      next_screen_remove_nag: false,
      show_alternative_text: false,
      ldap_challenges_uncompleted: []
    }

    if (typeof props.nag_options !== 'undefined') {

      if (!props.nag_options['mfa_completed']) {
        this.state['force_mfa_setup'] = true;
      }

      if (props.nag_options['mfa_completed']) {
        this.state['last_nag_setup'] = true;
      }
    }


    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.continue_next = this.continue_next.bind(this);
  }


  handleAnswerChange(box_id, event) {
    let value = event.target.value;
    let answers = this.state.answers;

    answers[box_id] = value;
        
    this.setState({
      answers: answers
    });
  }  

  async handleSubmit(event) {
    event.preventDefault();
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    this.setState({
      loading: true
    });

    let formData = new FormData();
    for (var key in this.state.answers) {
      formData.append('answers[]', this.state.answers[key]);
    }
    formData.append('authenticity_token', csrf_token)

    try {
      let result = await fetch('/challengeresponse/ldap.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        if (typeof this.props.nag_options === 'undefined' && (data.data.recommend_setup_challenges || this.state.force_challenge_setup)) {
          if (data.data.force_setup_mfa || this.state.force_mfa_setup) {
            this.setState({
              loading: false,
              next_screen: '/challengeresponse/new'
            });                
          } else {
            this.setState({
              loading: false,
              recommend_setup_challenges: true
            });
          }          
        } else if (data.data.recommend_setup_mfa || this.state.force_mfa_setup) {
          if (data.data.force_setup_mfa || this.state.force_mfa_setup) {
            this.setState({
              loading: false,
              next_screen: '/mfa'
            }); 
          } else {
            this.setState({
              loading: false,
              recommend_setup_mfa: true
            });
          }
        } else if (this.state.last_nag_setup) {
          this.setState({
            loading: false,
            next_screen: '/',
            next_screen_remove_nag: true
          });
        } else {
          this.setState({
            loading: false,
            next_screen: '/myaccount',
            next_screen_remove_nag: false
          });
        }
      } else {
        this.setState({ error_message: data.message, loading: false });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }


  continue_next(event) {
    event.preventDefault();

    let nag_redirect = false;

    if (this.state.next_screen_remove_nag) {
      nag_redirect = this.props.remove_nag();
    }


    if (!nag_redirect) {
      this.props.history.push(this.state.next_screen);
    }
  }

  async componentDidMount() {
    if (
      typeof this.props.nag_options !== 'undefined' && 
      this.props.nag_options.ldap_chal_completed
    ) {
      this.props.history.push("/");
    }  

    // Show alternative text if that's enabled
    if (
      typeof this.props.nag_options !== 'undefined' && 
      this.props.nag_options.ldap_chap_show_alt
    ) {

      if (this.state.last_nag_setup) {
        this.setState({
          next_screen: '/',
          next_screen_remove_nag: true
        });
      } else {
        this.setState({
          next_screen: '/mfa',
        });        
      }

      try {
        let result = await fetch('/my_account.json', {credentials: 'same-origin'});

        result = await fetchErrorHandler(result);
        const data = await result.json();
        if (typeof this.props.resetInactivityTimer !== 'undefined') {
          this.props.resetInactivityTimer();
        }


        
        if (data.status == "ok") {
          this.setState({
            loading: false,
            show_alternative_text: true,       
            ldap_challenges_uncompleted: data.data.ldap_challenges_uncompleted
          });
        } else {
          throw Error([500, data.message]);
        }
      } catch (error) {
        this.setState({caughtError: error});
      }    

      // Don't load the anything else since it'll be forbidden
      return;
    }

    try {
      let result = await fetch('/challengeresponse/ldap.json', {credentials: 'same-origin'});

      result = fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        this.setState({
          loading: false,
          questions: data.data.questions,
          answers: data.data.answers
        })
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }  


  renderNagProgress() {
    return(
      <React.Fragment>
        <NagRenderProgress nag_options={this.props.nag_options} current="ldap_challenges" />
      </React.Fragment>
    )
  }

  renderSkipButton() {
    if (!this.props.nag_options.allow_skip_ldap_challenge || !this.props.nag_options.allow_skip) {
      return;
    }

    return (
      <React.Fragment>
        <button className="btn btn-primary" type="button" onClick={(e) => this.props.nagSkipIndividal(this,"ldap_challenges", e)}>Skip (remind me later)</button>&nbsp;
      </React.Fragment>
    )
  }  


  renderChallengeBox(question, i) {
    let answer = this.state.answers[i];
    let box_id = "answer_" + i;
    return (
      <div className="form-group" key={i}>
        <label>{question}</label>

        <input type="text" 
               className="form-control" 
               onChange={(e) => this.handleAnswerChange(i, e)}
               defaultValue={answer}
               id={box_id}
               autoComplete="off" />
      </div>
    );
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }
    
    var self=this;

    if (this.state.loading) {
      return (
        <Loading loading={this.state.loading} />
      );
    }

    if (this.state.next_screen && !this.state.show_alternative_text) {
      if (typeof this.props.nag_options !== 'undefined') {
        return (
         <div>
          <h1>Account setup</h1>
          {this.renderNagProgress()}

          <div className="nag-padded">
            <h3>Setup personal verification challenges</h3>
            <div className="alert alert-success">
              Your personal verification challenges have been setup.
            </div>

             <button className="btn btn-primary"
                     onClick={this.continue_next}>
               Continue
             </button>          
            </div>
          </div>            
        )
      }


      return (
         <div>
          <h1>Setup personal verification challenges</h1>
          <div className="alert alert-success">
            Your personal verification challenges have been setup.
          </div>

           <button className="btn btn-primary"
                   onClick={this.continue_next}>
              Continue
           </button>          
        </div>  
      );
    }

    if (typeof this.props.nag_options !== 'undefined') { 

      if (this.state.show_alternative_text) {
        return (
          <div>
            <h1>Account status</h1>
            {this.renderNagProgress()}
            <div className="nag-padded">
              <h3>Setup personal verification challenges</h3>
              
              <p>{this.props.nag_options.ldap_chal_alt_txt}</p>

              <ul>
                {this.state.ldap_challenges_uncompleted.map(function(question, i) {
                  return(
                    <li key={i}>{question}</li>
                  )
                })}
              </ul>

              <div className="pull-right">
               <button className="btn btn-primary"
                       onClick={this.continue_next}>
                  Continue
               </button>                   
              </div>  
            </div>
          </div>
        )
      }

      let nag_text = "We notice that you haven't yet setup your personal verification " +
        "questions, if you would like to do that now then please follow " + 
        "the instructions below, or click skip if you'd like to complete " + 
        "them later.";

      if (
        !this.props.nag_options.allow_skip_ldap_challenge || 
        !this.props.nag_options.allow_skip
      ) {        
        let nag_text = "We notice that you haven't yet setup your personal verification " +
        "questions, please follow the instructions below.";
      }

      return (
        <div>
          <h1>Account status</h1>
          {this.renderNagProgress()}
          <form onSubmit={this.handleSubmit}>
            <div className="nag-padded">
              <p>
                <strong>
                  {nag_text}
                </strong>
              </p>

              <h3>Setup personal verification challenges</h3>
              <PrivacyPolicyFormText />

              <RenderError error_message={this.state.error_message} />
                 {this.state.questions.map(function(question, i) {
                   return this.renderChallengeBox(question, i);
                 }.bind(this))}

            </div>
            <div className="pull-right">
              {this.renderSkipButton()}
              <input type="submit" className="btn btn-primary" value="Submit answers" />
            </div>  
          </form>        
        </div>
      );
    }

    if (this.state.recommend_setup_mfa) {
      return (
        <div>
          <h1>Setup personal verification challenges</h1>
          <div className="alert alert-success">
            Your challenge response questions/answers have been setup.
          </div>

          <p>
            We notice you haven't yet setup your multi factor authentication. If you forget your pasword you can access 
            your account by entering your mutli factor code. If you would like to do that now then click on the button 
            below.
          </p>

          <Link to='/mfa' className="btn btn-primary">Setup multi factor authentication</Link>          
        </div>
      )
    }

    return (
      <div>
        <h1>Setup personal verification challenges</h1>

        <p>
          The service desk may use this information to verify your identity 
          when you call them.
        </p>

        <PrivacyPolicyFormText />

        <RenderError error_message={this.state.error_message} />

        <form onSubmit={this.handleSubmit}>

           {this.state.questions.map(function(question, i) {
             return this.renderChallengeBox(question, i);
           }.bind(this))}

          <input type="submit" className="btn btn-primary" value="Submit answers" />&nbsp;
          <Link to='/myaccount' className="btn btn-primary">Cancel</Link>
        </form>

      </div>
    );
  }
}

export default ChallengeNewLdap;
