import React from "react"
import PropTypes from "prop-types";

class NagRenderProgress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1
    };

    if (this.props.current == "challenges") {
      this.state.step = 1;
    } else if (this.props.current == 'ldap_challenges') {
      this.state.step = 2;
    } else if (
      this.props.current == "mfa" && 
      !this.props.nag_options.ldap_chal_enabled &&
      !this.props.nag_options.ldap_chap_show_alt
    ) {
      this.state.step = 2;
    } else if (
      this.props.current  == "mfa" &&
      (
        this.props.nag_options.ldap_chal_enabled ||
        this.props.nag_options.ldap_chap_show_alt
      )
    ) {
      this.state.step = 3;
    }
  }

  renderStepFirstRow(name) {
    if (
      name == 'ldap_challenges' && 
      !this.props.nag_options.ldap_chal_enabled && 
      !this.props.nag_options.ldap_chap_show_alt
    ) {
      return(<div></div>);
    }

    let current_step = this.state.step;
    let step_class = "col-xs-4 bs-wizard-step";  
    if (
      !this.props.nag_options.ldap_chal_enabled && 
      !this.props.nag_options.ldap_chap_show_alt
    ) {
      step_class = "col-xs-6 bs-wizard-step";  
    }
    
    let step = 1;

    if (name == "challenges") {
      step = 1;
      name = "Challenge / Response";
    } else if (name == "ldap_challenges") {
      step = 2;
      name = "Personal details verification";
    } else if (
      name == "mfa" && 
      !this.props.nag_options.ldap_chal_enabled && 
      !this.props.nag_options.ldap_chap_show_alt
    ) {
      step = 2;
      name = "Multi factor authentication";
    } else if (
      name == "mfa" && 
      (
        this.props.nag_options.ldap_chal_enabled ||
        this.props.nag_options.ldap_chap_show_alt
      )
    ) {
      step = 3;
      name = "Multi factor authentication";
    }

    if (step == current_step) {
      step_class += " active";
    } else if (step > current_step) {
      step_class += " disabled";
    } else if (step < current_step) {
      step_class += " complete";
    }

    return (
      <div className={step_class}>
        <div className="text-center bs-wizard-stepnum">{name}</div>
      </div>
    )
  }

  renderStepSecondRow(name) {
    if (
      name == 'ldap_challenges' && 
      !this.props.nag_options.ldap_chal_enabled && 
      !this.props.nag_options.ldap_chap_show_alt
    ) {
      return(<div></div>);
    }

    let current_step = this.state.step;
    let step_class = "col-xs-4 bs-wizard-step";  
    if (
      !this.props.nag_options.ldap_chal_enabled && 
      !this.props.nag_options.ldap_chap_show_alt
    ) {
      step_class = "col-xs-6 bs-wizard-step";  
    }
    
    let step = 1;

    if (name == "challenges") {
      step = 1;
    } else if (name == "ldap_challenges") {
      step = 2;
    } else if (
      name == "mfa" && 
      !this.props.nag_options.ldap_chal_enabled && 
      !this.props.nag_options.ldap_chap_show_alt
    ) {      
      step = 2;
    } else if (
      name == "mfa" &&
      (
        this.props.nag_options.ldap_chal_enabled ||
        this.props.nag_options.ldap_chap_show_alt
      )
    ) {
      step = 3;
    }

    if (step == current_step) {
      step_class += " active";
    } else if (step > current_step) {
      step_class += " disabled";
    } else if (step < current_step) {
      step_class += " complete";
    }

    return (
      <div className={step_class}>
        <div className="progress"><div className="progress-bar"></div></div>
        <a href="javascript:void(0);" className="bs-wizard-dot"></a>
      </div>
    )
  }

  render() {
    

    return (
      <div>
        <div className="row bs-wizard row-1">
          {this.renderStepFirstRow("challenges")}
          {this.renderStepFirstRow("ldap_challenges")}
          {this.renderStepFirstRow("mfa")}
        </div>
        <div className="row bs-wizard row-2">
          {this.renderStepSecondRow("challenges")}
          {this.renderStepSecondRow("ldap_challenges")}
          {this.renderStepSecondRow("mfa")}
        </div>  
      </div>    
    )
  }
}

export default NagRenderProgress;
