import React from "react"
import PropTypes from "prop-types";
import {Link} from "react-router-dom"

import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import Loading from '../../misc/Loading.jsx';

import RenderProgress from './RenderProgress';
import RenderError from '../../misc/RenderError';

class ForgotPasswordCheckChallenges extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      caughtError: '',      
      error_message: '',
      answers:{},
      pw_togggles: {},
      failed_attempts: 0,
    }

    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleChallengeCheck = this.handleChallengeCheck.bind(this);
    this.togglePwView = this.togglePwView.bind(this);

  }

  handleAnswerChange(box_id, event) {
    let value = event.target.value;
    let answers = this.state.answers;

    answers[box_id] = value;
        
    this.setState({
      answers: answers
    });
  }  

 async handleChallengeCheck(event) {
    event.preventDefault();
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    this.setState({
      loading: true
    });

    let formData = new FormData();
    for (var key in this.state.answers) {
      formData.append('answers[]', this.state.answers[key]);
    }
    formData.append('authenticity_token', csrf_token);

    try {
      let result = await fetch('/session/forgotpassword/check_challenges.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();
      if (data.status == "ok") {
       if (data.data.tokens.length < 1) {        
        // Disable personal verification challenges for now
        //if (data.data.tokens.length < 1 && data.data.extra_challenges.length < 1) {
          this.props.childStateSet({
            loading: false,
            show_help_desk_details: true
          });
        } else {        
          this.props.childStateSet({
            step: 3,
            tokens: data.data.tokens,
            //ldap_challenges: data.data.extra_challenges
          });
        }
      } else {
        let failed_attempts = this.state.failed_attempts;
        failed_attempts++;

        if (failed_attempts == 3) {
          this.props.childStateSet({
            loading: false,
            show_help_desk_details: true
          });
        } else {        
          this.setState({ error_message: data.message, loading: false, failed_attempts: failed_attempts });
        }
      }
    } catch (error) {
      this.setState({caughtError: error});
    }    
  }

  togglePwView(question_id, event) {
    event.preventDefault();

    let pw_togggles = this.state.pw_togggles;
    if (
      typeof pw_togggles[question_id] === 'undefined' || 
      !pw_togggles[question_id]
    ) {
      pw_togggles[question_id] = true;
    } else {
      pw_togggles[question_id] = false;
    }

    this.setState({
      pw_togggles: pw_togggles
    });
  }

  renderChallengeBox(self, challenge, i) {
    let question_id = "question-" + (i + 1);

    let toggle_pw_classes = "input-group-text fa";
    let toggle_pw_box_class = "password";

    if (
      typeof this.state.pw_togggles[question_id] !== 'undefined' &&
      this.state.pw_togggles[question_id]
    ) {
      toggle_pw_classes += " fa-eye";
      toggle_pw_box_class = "text";
    } else {
      toggle_pw_classes += " fa-eye-slash";
    }

    return (
      <div className="form-group" key={i}>
        <label>{challenge}</label>

        <div className="input-group">
          <input type={toggle_pw_box_class} id={question_id} 
                 onChange={(e) => self.handleAnswerChange(question_id, e)}
                 className="form-control" />

          <div className="input-group-append">
            <i className={toggle_pw_classes}
               onClick={(e) => self.togglePwView(question_id, e)}></i>
          </div>
        </div>
      </div>
    )
  }


  render() {
    var self = this;

    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 portal">
          <Loading loading={this.state.loading} />
        </div>
      );
    }
    
    let top_class = "col-sm-12";

    if (typeof window.gon !== 'undefined' && typeof window.gon.sso !== 'undefined' && window.gon.sso == true) {
      top_class = top_class + " in-modal"
    }       
     
    let privacypolicy = "";

    if (gon.show_privacy_policy && !window.location.href.includes('privacypolicy')) {
      privacypolicy = (
        <p className="portal_footer">
          <Link to="/privacypolicy">Privacy Policy</Link>
        </p>
      );
    }     
   return (
      <div className={top_class}>
        <div className="row">
          <div className="col-md-1 col-sm-12 portal-logo">
            <Link to='/'><img src={window.gon.logo_url} /></Link>
          </div>
          <div className="col-md-11 col-sm-12">
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <h1>Forgotten password</h1>        

            <RenderProgress step={2} />
            
            <RenderError error_message={this.state.error_message} />

            <p>
              Please answer the following questions. If you answer these questions correctly, you will then be able to 
              reset your password.
            </p>

            <form onSubmit={this.handleChallengeCheck}>
              {this.props.parentState.challenges.map(function(challenge, i) {
                return self.renderChallengeBox(self, challenge, i);
              })}

              <input type="submit" className="btn btn-primary" value="Check answers" />
            </form>
          </div>
        </div>  
        <div className="row">
          <div className="col-sm-12 footer">
            {privacypolicy}
          </div>
        </div>                  
      </div>          
    );
  }
}

export default ForgotPasswordCheckChallenges
