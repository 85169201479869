import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import Loading from '../../misc/Loading.jsx';
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import NagRenderProgress from '../nagcomponents/RenderProgress';
import PrivacyPolicyFormText from '../../misc/PrivacyPolicyFormText';

class MFANewOTP extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      error_message: '',
      description: '',
      loading: true,
      step: 1,
      token_serial: '',
      token_otp_url: '',
      token_otp_key: '',
      token_otp_img: '',
      token_google_url: '',
      token_google_img: '',
      recommend_setup_challenges: false,
      last_nag_setup: false,
      next_screen: '',
      next_screen_remove_nag: false         
    }

    if (typeof props.nag_options !== 'undefined') {
      if (typeof props.remove_nag !== 'undefined') {
        this.state['last_nag_setup'] = true;
      }
    }    

    this.token_input = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleInitialSubmit = this.handleInitialSubmit.bind(this);
    this.handleFinalSubmit = this.handleFinalSubmit.bind(this);
    this.GoToStep3 = this.GoToStep3.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.continue_next = this.continue_next.bind(this);

  }

  handleChange(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.id 

    this.setState({
      [name] : value
    });
  }


  async handleInitialSubmit(event) {
    event.preventDefault();

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('token_type', "totp");
    formData.append('description', this.state.description);
    formData.append('authenticity_token', csrf_token)

    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/tokens.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });
      result = await fetchErrorHandler(result);
      const data = await result.json();
      
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        this.setState({ 
          step: 2, 
          loading: false,  
          token_serial: data.data.token_serial,
          token_google_img: data.data.token_google_img,
          token_google_url: data.data.token_google_url,
          token_otp_img: data.data.token_otp_img,
          token_otp_key: data.data.token_otp_key,
          token_otp_url: data.data.token_otp_url,
          error_message: ''
        });
      } else {
        this.setState({ error_message: data.message, loading: false });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }    
  }

  GoToStep3(event) {
    this.setState({
      step: 3,
      error_message: ''
    });
    event.preventDefault();
  }

  async handleFinalSubmit(event) {
    event.preventDefault();

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('token_type', "totp");
    formData.append('mfa_serial', this.state.token_serial);
    formData.append('mfa_token', this.token_input.current.value );
    formData.append('authenticity_token', csrf_token)

    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/tokens/check.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        if (typeof this.props.nag_options === 'undefined' && data.data.recommend_setup_challenges) {
          if (data.data.force_setup_challenges) {
            this.setState({
              loading: false,
              next_screen: '/challengeresponse/new'
            });            
          } else {
            this.setState({
              error_message: '',
              loading: false,
              recommend_setup_challenges: true
            });
          }
        } else if (typeof this.props.nag_options !== 'undefined') {
          this.setState({
            loading: false,
            next_screen: '/',
            next_screen_remove_nag: true
          });
        } else {
          this.setState({
            loading: false,
            next_screen: '/mfa'
          });    
        }
      } else {
        this.setState({ error_message: data.message, loading: false });

        //  We don't update the token dom from state for security reasons
        //  so we need to empty it via a reference
        this.token_input.current.value = "";          
      }
    } catch (error) {
      this.setState({caughtError: error});
    }  
  }  

  continue_next(event) {
    event.preventDefault();

    let nag_redirect = false;

    if (this.state.next_screen_remove_nag) {
      nag_redirect = this.props.remove_nag();
    }


    if (!nag_redirect) {
      this.props.history.push(this.state.next_screen);
    }
  }

  async componentDidMount() {
    if (typeof this.props.nag_options !== 'undefined' && this.props.nag_options.mfa_completed) {
      this.props.history.push("/mfa");
    }  


    try {
      let result = await fetch('/logged_in_check.json', {credentials: 'same-origin'});

      result = fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        this.setState({
          loading: false
        })
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async handleCancel(event) {
    event.preventDefault();
    
    this.setState({
      loading: true,
    });

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('mfa_serial', this.state.token_serial);
    formData.append('authenticity_token', csrf_token)

    try {
      let result = await fetch('/tokens/cancel.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }
      
      if (data.status == "ok") {
        this.props.history.push("/mfa");
      } else {
        this.props.history.push("/mfa");
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }  

  renderNagProgress() {
    return(
      <React.Fragment>
        <NagRenderProgress nag_options={this.props.nag_options} current="mfa" />
      </React.Fragment>
    )
  }  

  renderSkipButton() {
    if (!this.props.nag_options.allow_skip_mfa || !this.props.nag_options.allow_skip) {
      return;
    }

    return (
      <React.Fragment>
        <button type="button" className="btn btn-primary" onClick={(e) => this.props.nagSkipIndividal(this,"mfa", e)}>Skip (remind me later)</button>&nbsp;
      </React.Fragment>
    )
  }

  renderProgress(step) {
    let step_1 = "col-xs-4 bs-wizard-step active";
    let step_2 = "col-xs-4 bs-wizard-step disabled";
    let step_3 = "col-xs-4 bs-wizard-step disabled";

    if (step == 2) {
      step_1 = "col-xs-4 bs-wizard-step complete";
      step_2 = "col-xs-4 bs-wizard-step active";
      step_3 = "col-xs-4 bs-wizard-step disabled";
    } else if (step == 3) {
      step_1 = "col-xs-4 bs-wizard-step complete";
      step_2 = "col-xs-4 bs-wizard-step complete";
      step_3 = "col-xs-4 bs-wizard-step active";      
    }

    return (
        <div className="row bs-wizard">
          <div className={step_1}>
            <div className="text-center bs-wizard-stepnum">Step 1</div>
            <div className="progress"><div className="progress-bar"></div></div>
            <a href="javascript:void(0);" className="bs-wizard-dot"></a>
          </div>
                
          <div className={step_2}>
            <div className="text-center bs-wizard-stepnum">Step 2</div>
            <div className="progress"><div className="progress-bar"></div></div>
            <a href="javascript:void(0);" className="bs-wizard-dot"></a>
          </div>
                
          <div className={step_3}>
            <div className="text-center bs-wizard-stepnum">Step 3</div>
            <div className="progress"><div className="progress-bar"></div></div>
            <a href="javascript:void(0);" className="bs-wizard-dot"></a>
          </div>
        </div>
    )
  }

  renderNagProgress() {
    return(
      <React.Fragment>
        <NagRenderProgress nag_options={this.props.nag_options} current="mfa" />
      </React.Fragment>
    )
  }

  renderError() {
    if (this.state.error_message) {
      return(
        <div className="alert alert-danger">{this.state.error_message}</div>
      );
    }
    return;
  }


  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }
    
    var self=this;

    if (this.state.loading) {
      return (
        <Loading loading={this.state.loading} />
      );
    }

    if (this.state.recommend_setup_challenges) {
      return (
        <div>
          <h1>Setup multi factor authentication</h1>

          <div className="alert alert-success">
            Your multi factor authentication token has been setup and verified.
          </div>

          <p>
            We notice you haven't yet setup your challenge response answers. If you forget your pasword you can access 
            your account by answering your security questions. If you would like to do that now then click on the button 
            below.
          </p>

          <Link to='/challengeresponse/new' className="btn btn-primary">Setup challenge response answers</Link>
        </div>
      )
    }

    if (this.state.next_screen) {
      if (typeof this.props.nag_options !== 'undefined') {
        return (
         <div>
          <h1>Account status</h1>
          {this.renderNagProgress()}

          <div className="nag-padded">
            <h3>Setup multi factor authentication</h3>
            <div className="alert alert-success">
              Your secondary smartphone has been configured
            </div>

             <button className="btn btn-primary"
                     onClick={this.continue_next}>
               Continue
             </button>          
            </div>
          </div>            
        )
      }

      return (
        <div>
          <h3>Setup multi factor authentication</h3>
          <div className="alert alert-success">
            Your secondary smartphone has been configured
          </div>

           <button className="btn btn-primary"
                   onClick={this.continue_next}>
              Continue
           </button>          
         </div>  
      );      
    }


    if (this.state.step == 1) {
      if (typeof this.props.nag_options !== 'undefined') {    
        return (
          <div>
            <h1>Account status</h1>
            {this.renderNagProgress()}
            
            <form onSubmit={this.handleInitialSubmit}>

              <div className="nag-padded">
                <h3>Setup multi factor authentication</h3>
                {this.renderProgress(this.state.step)}

                <h3>Register a smartphone</h3>
                <p>Please enter a description below to help identify your smartphone.</p>

                {this.renderError()}          
                <div className="form-group">
                  <input className="form-control" id="description" placeholder="Description" 
                      value={this.state.description} onChange={this.handleChange} />
                </div>
              </div>
              <div className="pull-right">
                <input type="submit" className="btn btn-primary" value="Register" />&nbsp;
                <Link to='/mfa' className="btn btn-primary">Cancel</Link>&nbsp;         
                {this.renderSkipButton()}

              </div>
            </form>
          </div>
        )
      }

      return (
        <div>
          <h1>Setup multi factor authentication</h1>
          {this.renderProgress(this.state.step)}

                <h3>Register a smartphone</h3>
                <p>Please enter a description below to help identify your smartphone.</p>

          {this.renderError()}          
          <form onSubmit={this.handleInitialSubmit}>
            <div className="form-group">
              <input className="form-control" id="description" placeholder="Description" 
                  value={this.state.description} onChange={this.handleChange} />
            </div>

            <input type="submit" className="btn btn-primary" value="Register" />&nbsp;
            <Link to='/mfa' className="btn btn-primary">Cancel</Link>
          </form>
        </div>
      )
    }

    if (this.state.step == 2) {
      if (typeof this.props.nag_options !== 'undefined') {    
        return (
          <div>
            <h1>Account status</h1>
            {this.renderNagProgress()}

            <div className="nag-padded">
              <h3>Setup multi factor authentication</h3>        
              {this.renderProgress(this.state.step)}


              <p>
                Choose your smartphone type below and follow the instructions.  
                If you require assistance please contact the Service Desk
              </p>

              <Tabs defaultActiveKey="android" transition={false} id="otp-tabs">
                <Tab eventKey="android" title="Android">
                  <div className="row">
                    <div className="col-md-8 col-sm-12 mfa-instructions">
                      <strong>Install the Google Authenticator app for Android.</strong>
                      <ol>
                        <li>On your phone, go to the Google Play Store.</li>
                        <li>
                            Search for Google Authenticator. <br />
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                               target="_blank">
                              (Download from the Google Play Store)
                            </a>
                        </li>
                        <li>Download and install the application.</li>
                      </ol>

                      <strong>Next, open and configure Google Authenticator.</strong>
                      <ol>
                        <li>In Google Authenticator, touch Menu and select "Set up account."</li>
                        <li>Select "Scan a barcode."</li>
                        <li>Use your phone's camera to scan this barcode.</li>
                        <li>Once you have scanned the barcode, click the continue button.</li>
                      </ol>
                    </div>
                    <div className="col-md-4 col-sm-12 mfa-qrcode">
                      <a href={this.state.token_google_url}><img src={this.state.token_google_img} /></a>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="iphone" title="iPhone">   
                  <div className="row">
                    <div className="col-md-8 col-sm-12 mfa-instructions">
                      <strong>On your iPhone, tap the App Store icon.</strong>
                      <ol>
                        <li>On your phone, go to the App Store.</li>
                        <li>
                            Search for Google Authenticator. <br />
                            <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank">
                              (Download from the App Store)
                            </a>
                        </li>
                        <li>Tap the app, and then tap Free to download and install it.</li>
                      </ol>

                      <strong>Next, open and configure Google Authenticator.</strong>
                      <ol>
                        <li>In Google Authenticator, tap "+", and then "Scan Barcode."</li>
                        <li>Use your phone's camera to scan this barcode.</li>
                        <li>Once you have scanned the barcode, click the continue button.</li>
                      </ol>
                    </div>
                    <div className="col-md-4 col-sm-12 mfa-qrcode">
                      <a href={this.state.token_google_url}><img src={this.state.token_google_img} /></a>
                    </div>
                  </div>                     
                </Tab>
                <Tab eventKey="other" title="Other">
                  <div className="row">
                    <div className="col-md-8 col-sm-12 mfa-instructions">
                      <strong>Find a compatible two-factor app.</strong>
                      <ol>
                        <li>
                          Try searching your device's app store for Google Authenticator. <br />
                          Many devices have compatible apps.
                        </li>
                        <li>
                          Try looking for an app that supports "TOTP security tokens" or "RFC6238"
                        </li>
                        <li>Download and install the application.</li>
                      </ol>

                      <strong>Next, open and configure the app.</strong>
                      <ol>
                        <li>Enter the data below or scan the code as the app instructs.</li>
                        <li>Once you have scanned the barcode, click the continue button.</li>
                      </ol>
                    </div>
                    <div className="col-md-4 col-sm-12 mfa-qrcode">
                      <p><a href={this.state.token_otp_url}><img src={this.state.token_otp_img} /></a></p>
                      <p>
                        <strong>Identifier:</strong> {this.state.token_serial} <br />
                        <strong>Secret:</strong> {this.state.token_otp_key} <br />
                        <strong>Type:</strong> TOTP
                      </p>
                    </div>
                  </div>               
                </Tab>
              </Tabs>

              <button onClick={this.GoToStep3} className="btn btn-primary">Continue</button>&nbsp;
              <button className="btn btn-primary" onClick={this.handleCancel}>Cancel</button>
            </div>
          </div>
        )
      }

      return (
        <div>
          <h1>Setup multi factor authentication</h1>        
          {this.renderProgress(this.state.step)}


          <p>
            Choose your smartphone type below and follow the instructions.  
            If you require assistance please contact the Service Desk
          </p>


          <Tabs defaultActiveKey="android" transition={false} id="otp-tabs">
            <Tab eventKey="android" title="Android">
              <div className="row">
                <div className="col-md-8 col-sm-12 mfa-instructions">
                  <strong>Install the Google Authenticator app for Android.</strong>
                  <ol>
                    <li>On your phone, go to the Google Play Store.</li>
                    <li>
                        Search for Google Authenticator. <br />
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                           target="_blank">
                          (Download from the Google Play Store)
                        </a>
                    </li>
                    <li>Download and install the application.</li>
                  </ol>

                  <strong>Next, open and configure Google Authenticator.</strong>
                  <ol>
                    <li>In Google Authenticator, touch Menu and select "Set up account."</li>
                    <li>Select "Scan a barcode."</li>
                    <li>Use your phone's camera to scan this barcode.</li>
                    <li>Once you have scanned the barcode, click the continue button.</li>
                  </ol>
                </div>
                <div className="col-md-4 col-sm-12 mfa-qrcode">
                  <a href={this.state.token_google_url}><img src={this.state.token_google_img} /></a>
                </div>
              </div>
            </Tab>
            <Tab eventKey="iphone" title="iPhone">   
              <div className="row">
                <div className="col-md-8 col-sm-12 mfa-instructions">
                  <strong>On your iPhone, tap the App Store icon.</strong>
                  <ol>
                    <li>On your phone, go to the App Store.</li>
                    <li>
                        Search for Google Authenticator. <br />
                        <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank">
                          (Download from the App Store)
                        </a>
                    </li>
                    <li>Tap the app, and then tap Free to download and install it.</li>
                  </ol>

                  <strong>Next, open and configure Google Authenticator.</strong>
                  <ol>
                    <li>In Google Authenticator, tap "+", and then "Scan Barcode."</li>
                    <li>Use your phone's camera to scan this barcode.</li>
                    <li>Once you have scanned the barcode, click the continue button.</li>
                  </ol>
                </div>
                <div className="col-md-4 col-sm-12 mfa-qrcode">
                  <a href={this.state.token_google_url}><img src={this.state.token_google_img} /></a>
                </div>
              </div>                     
            </Tab>
            <Tab eventKey="other" title="Other">
              <div className="row">
                <div className="col-md-8 col-sm-12 mfa-instructions">
                  <strong>Find a compatible two-factor app.</strong>
                  <ol>
                    <li>
                      Try searching your device's app store for Google Authenticator. <br />
                      Many devices have compatible apps.
                    </li>
                    <li>
                      Try looking for an app that supports "TOTP security tokens" or "RFC6238"
                    </li>
                    <li>Download and install the application.</li>
                  </ol>

                  <strong>Next, open and configure the app.</strong>
                  <ol>
                    <li>Enter the data below or scan the code as the app instructs.</li>
                    <li>Once you have scanned the barcode, click the continue button.</li>
                  </ol>
                </div>
                <div className="col-md-4 col-sm-12 mfa-qrcode">
                  <p><a href={this.state.token_otp_url}><img src={this.state.token_otp_img} /></a></p>
                  <p>
                    <strong>Identifier:</strong> {this.state.token_serial} <br />
                    <strong>Secret:</strong> {this.state.token_otp_key} <br />
                    <strong>Type:</strong> TOTP
                  </p>
                </div>
              </div>               
            </Tab>
          </Tabs>

          <button onClick={this.GoToStep3} className="btn btn-primary">Continue</button>&nbsp;
          <button className="btn btn-primary" onClick={this.handleCancel}>Cancel</button>         
        </div>
      )
    }

    if (this.state.step == 3) {
      if (typeof this.props.nag_options !== 'undefined') {    
        return (
          <div>
            <h1>Account status</h1>
            {this.renderNagProgress()}
            <div className="nag-padded">
              <h3>Setup multi factor authentication</h3>
              {this.renderProgress(this.state.step)}

              <p>
                Please enter the one time password code in the box below
              </p>
              {this.renderError()}


              <form onSubmit={this.handleFinalSubmit}>
                <div className="form-group">
                  <input type="password" id="token" placeholder="Enter multifactor code" className="form-control"
                          ref={this.token_input} autofocus="true"/>
                </div>

                <input type="submit" className="btn btn-primary" value="Finish" />&nbsp;
                <button className="btn btn-primary" onClick={this.handleCancel}>Cancel</button>                
              </form>
            </div>
          </div>
        );
      }

      return (
        <div>
          <h1>Setup multi factor authentication</h1>        
          {this.renderProgress(this.state.step)}

          <p>
            Enter your token below to validate that it is working correctly.
          </p>
          {this.renderError()}


          <form onSubmit={this.handleFinalSubmit}>
            <div className="form-group">
              <input type="password" id="token" placeholder="Enter multifactor code" className="form-control"
                      ref={this.token_input} autofocus="true"/>
            </div>

            <input type="submit" className="btn btn-primary" value="Finish" />&nbsp;
            <button className="btn btn-primary" onClick={this.handleCancel}>Cancel</button>            
          </form>
        </div>        
      );
    }

    return (<div></div>);
  }
}

export default MFANewOTP
