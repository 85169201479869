import React from "react"
import PropTypes from "prop-types";
import Linkify from 'react-linkify';


class RenderSuccess extends React.Component {
  render() {
    if (this.props.success_message) {
      return(
        <div className="alert alert-success">{this.props.success_message}</div>
      );
    }
    return (<div></div>);
  }
}

export default RenderSuccess;
