import React from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import fetchErrorHandler from '../errors/fetchErrrorHandler.js';
import readCookie from '../misc/readCookie.js';
import Loading from '../misc/Loading.jsx';
import Linkify from 'react-linkify';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      username: '',
      password: '',
      error_message: '',
      loading: true,
      pw_togggles: {}
    }

    this.password_input = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.id 

    this.setState({
      [name] : value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();    

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('username', this.state.username);
    formData.append('password', this.state.password);
    formData.append('authenticity_token', csrf_token)

    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/session/login.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      this.setState({
        loading: false
      });

      if (data.status == "ok") {
        this.props.history.push('/mfa')
      } else {
        this.setState({
          password: '',
          error_message: data.message
        });

        //  We don't update the password dom from state for security reasons
        //  so we need to empty it via a reference
        this.password_input.current.value = "";
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  componentDidMount() {
    if (window.location.search.substr(1) == 'redirect_to=mfa') {
      this.props.history.push("/mfa")
    } else if (window.location.search.substr(1) == 'redirect_to=forcepwchange'){
      this.props.history.push("/forcepwchange")
    }

    this.setState({
      loading: false
    });
  }

  renderError() {
    if (this.state.error_message) {
      return(
        <div className="alert alert-danger"><Linkify>{this.state.error_message}</Linkify></div>
      );
    }
    return;
  }

  togglePwView(form_id, event) {
    event.preventDefault();

    let pw_togggles = this.state.pw_togggles;
    if (
      typeof pw_togggles[form_id] === 'undefined' || 
      !pw_togggles[form_id]
    ) {
      pw_togggles[form_id] = true;
    } else {
      pw_togggles[form_id] = false;
    }

    this.setState({
      pw_togggles: pw_togggles
    });
  }  

  render () {
    let self=this;
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }
    let privacypolicy = "";

    if (gon.show_privacy_policy) {
      privacypolicy = (
          <p className="footer">
            <Link to="/privacypolicy">Privacy Policy</Link>
          </p>
      );
    }


    let top_class = "login_box responsive";
    let login_text = "Self Service Password Reset and MFA Portal";
    if (typeof window.gon !== 'undefined' && typeof window.gon.sso !== 'undefined' && window.gon.sso == true) {
      top_class = top_class + " in-modal"
      login_text = "Please login to change your expired password.";
    }      

    let toggle_pw_classes = "input-group-text fa";
    let toggle_pw_box_class = "password";

    if (
      typeof this.state.pw_togggles['password'] !== 'undefined' &&
      this.state.pw_togggles['password']
    ) {
      toggle_pw_classes += " fa-eye";
      toggle_pw_box_class = "text";
    } else {
      toggle_pw_classes += " fa-eye-slash";
    }          

    return (
      <div className={top_class}>
        <Loading loading={this.state.loading}>

          <div className="col-sm-12">
            <div className="logo">
              <img src={window.gon.logo_url} />
            </div>

            <h1 className="always_display_sso">{login_text}</h1>

            {this.renderError()}

            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input type="text" id="username" placeholder="Username or e-mail" className="form-control"
                      value={this.state.username} onChange={this.handleChange}/>
              </div>

              <div className="form-group">
                <div className="input-group">
                  <input type={toggle_pw_box_class} id="password" 
                       placeholder="Password" className="form-control" 
                       ref={this.password_input} onChange={this.handleChange} />
                  <div className="input-group-append">
                    <i className={toggle_pw_classes}
                     onClick={(e) => self.togglePwView('password', e)}></i>
                  </div>   
                </div>
              </div>

              <div className="form-group">
                <Link to="/reset">Forgotten password?</Link>
              </div>
              <input type="submit" className="btn btn-primary" value="Login" />
            </form>
          </div>

          {privacypolicy}
        </Loading>
      </div>
    );
  }
}

export default Login
