import React from "react"
import PropTypes from "prop-types";
import Linkify from 'react-linkify';


class RenderError extends React.Component {
  render() {
    if (this.props.error_message) {
      return(
        <div className="alert alert-danger"><Linkify>{this.props.error_message}</Linkify></div>
      );
    }
    return (<div></div>);
  }
}

export default RenderError;
