import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import fetchErrorHandler from '../errors/fetchErrrorHandler.js';
import readCookie from '../misc/readCookie.js';
import Loading from '../misc/Loading.jsx';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      caughtError: '',
      html: ''
    }
  }


  async componentDidMount() {
    try {
      let result = await fetch('/privacy_policy', {credentials: 'same-origin'});

      result = await fetchErrorHandler(result);

      let data = await result.text();

      this.setState({
        loading: false,
        html: data
      });
      
    } catch (error) {
      this.setState({caughtError: error});
    }
  }


  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 portal">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    return(
      <div className="row">
        <div className="col-sm-12" 
             dangerouslySetInnerHTML={{__html: this.state.html}} />
      </div>
    );
 
    
  }
}

export default PrivacyPolicy
