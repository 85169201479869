import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"

class Error404 extends React.Component {
  constructor(props) {
    super(props);

    this.disableError = this.disableError.bind(this);    
  }

  disableError(event) {
    if (typeof this.props.disableError !== 'undefined') {
      this.props.disableError(event);
    }
  }

  render() {
    return (
      <div className="login_box responsive">
        <div className="col-sm-12">
          <div className="logo">
            <img src={window.gon.logo_url}/>

            <h1>Page not found</h1>

            <p>The page that you are looking for may have been removed or renamed.</p>
            <p><Link to='/' onClick={this.disableError}>Click here to go back</Link></p>
          </div>
        </div>
      </div>
    )
  }
}

export default Error404
