import React from "react";
import PropTypes from "prop-types";
import Loader from 'react-loader-spinner'

class Loading extends React.Component {
  constructor(props) {
    super(props);
    
  }

  classes() {
    if (this.props.loading) {
      return "loader";
    } else {
      return "loader-hidden";
    }

  }

  render() {
    var self=this;
    return (
      <React.Fragment>
        <div className={this.classes()}>
          <div className="center">
            <Loader type="Rings" color="#00BFFF" height="100"  width="100" />   
          </div>
        </div>

        {self.props.children}
      </React.Fragment>
    );

  }
}


export default Loading;
