import React from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import fetchErrorHandler from '../errors/fetchErrrorHandler.js';
import readCookie from '../misc/readCookie.js';
import Linkify from 'react-linkify';

import CheckUsername from './forgotpassword/CheckUsername';
import CheckChallenges from './forgotpassword/CheckChallenges';
import CheckMFA from './forgotpassword/CheckMFA';
import CheckLdapChallenges from './forgotpassword/CheckLdapChallenges';
import ChangePassword from './forgotpassword/ChangePassword';


class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      username: '',
      tokens: [],
      challenges: [],
      ldap_challenges: [],
      password_policy_text: [],
      password_policy: {},
      step: 1,
      show_help_desk_details: false,
    }
  }

  childStateSet(stateData) {
    this.setState(stateData);
  }

  render () {
    var self = this;
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    let top_class = "col-sm-12";

    if (typeof window.gon !== 'undefined' && typeof window.gon.sso !== 'undefined' && window.gon.sso == true) {
      top_class = top_class + " in-modal"
    }            

    if (this.state.show_help_desk_details) {
      return(
        <div className={top_class}>
          <div className="row">
            <div className="col-md-1 col-sm-12 portal-logo">
              <Link to='/'><img src={window.gon.logo_url} /></Link>
            </div>
            <div className="col-md-11 col-sm-12">
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <h1>Forgotten password</h1>                    

              <Linkify>{window.gon.forgot_pw_helpdesk_details}</Linkify>
            </div>
          </div>

        </div>
      );
    }

    if (this.state.step == 2) {
     return (
       <CheckChallenges childStateSet={this.childStateSet.bind(this)} parentState={this.state} />
     );
    }    

    if (this.state.step == 3) {
      if (this.state.tokens.length < 1 && this.state.ldap_challenges.length > 1) {
       return (
         <CheckLdapChallenges childStateSet={this.childStateSet.bind(this)} parentState={this.state} />
       );
      } else if (this.state.tokens.length > 0) {
        return (
           <CheckMFA childStateSet={this.childStateSet.bind(this)} parentState={this.state} />
        )
      } else {
        return(
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-1 col-sm-12 portal-logo">
                <Link to='/'><img src={window.gon.logo_url} /></Link>
              </div>
              <div className="col-md-11 col-sm-12">
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <h1>Forgotten password</h1>                    

                <Linkify>{window.gon.forgot_pw_helpdesk_details}</Linkify>
              </div>
            </div>
          </div>
        );        
      }
    }

    if (this.state.step == 4) {
      return (
         <ChangePassword childStateSet={this.childStateSet.bind(this)} parentState={this.state} />
      );           
    }

    if (this.state.step == 5 && (typeof window.gon.sso === 'undefined' || window.gon.sso != true)) {
      return (
        <div className="col-sm-12">
          <div className="row">
            <div className="col-md-1 col-sm-12 portal-logo">
              <Link to='/'><img src={window.gon.logo_url} /></Link>
            </div>
            <div className="col-md-11 col-sm-12">
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <h1>Forgotten password</h1>

              <div className="alert alert-success">
                Your password has been successfuly reset.
              </div> 

              <Link to="/" className="btn btn-primary">Login</Link>
        
            </div>
          </div>  
        </div>      
      );
    } else if (this.state.step == 5 && typeof window.gon.sso !== 'undefined' && window.gon.sso == true) {
      return (
        <div className="col-sm-12">
          <div className="row">
            <div className="col-md-1 col-sm-12 portal-logo">
              <Link to='/'><img src={window.gon.logo_url} /></Link>
            </div>
            <div className="col-md-11 col-sm-12">
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <h1>Forgotten password</h1>

              <div className="alert alert-success">
                Your password has been successfuly reset. You can now close this window down and login.
              </div> 
            </div>
          </div>  
        </div>      
      );      
    }

    return (
      <CheckUsername childStateSet={this.childStateSet.bind(this)} />
    );
  }
}

export default ForgotPassword
