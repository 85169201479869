import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import fetchErrorHandler from '../errors/fetchErrrorHandler.js';
import Loading from '../misc/Loading.jsx';

class NagScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nag_options: props.nag_options
    }

    this.completeRegistration = this.completeRegistration.bind(this);
  }

  // We no longer want this summary screen, let's just redirect to the first set
  // of registrations that's not completed inside component did mount
  componentDidMount() {
    this.completeRegistration();   
  }

  completeRegistration() {
    if (!this.state.nag_options.challenges_completed) {
      this.props.history.push("/challengeresponse/new");
    } else if (
      (
        this.state.nag_options.ldap_chal_enabled || 
        this.props.nag_options.ldap_chap_show_alt
      ) && !this.state.nag_options.ldap_chal_completed
    ) {
      this.props.history.push("/myaccount/personal_challenges");
    } else if (!this.state.nag_options.mfa_completed) {
      this.props.history.push("/mfa");
    } else {
      this.props.handleRedirect();
    }
  }

  render() {
    // We no longer want this summary screen, let's just redirect to the first 
    // set of registrations that's not completed inside component did mount
    return (
      <div></div>
    )
  }
}

export default NagScreen

