import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import fetchErrorHandler from '../errors/fetchErrrorHandler.js';
import Loading from '../misc/Loading.jsx';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      caughtError: '',
    }
    this.GoToLink = this.GoToLink.bind(this);
  }

  GoToLink(link) {
    this.props.history.push(link);
  }

  async componentDidMount() {
    try {
      let result = await fetch('/logged_in_check.json', {credentials: 'same-origin'});

      result = await fetchErrorHandler(result);
      const data = await result.json();
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }
      
      if (data.status == "ok") {
        this.setState({
          loading: false
        });
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <Loading loading={this.state.loading} />
      );
    }

    return (
      <div>
        <div className="home-blocks">
          <div className="block" onClick={(e) => this.GoToLink('/changepassword')}>
            <i className="fa fa-key large-icon"></i>
            <h2>Change Password</h2>
            <p>Change your current password</p>
          </div>

          <div className="block" onClick={(e) => this.GoToLink('/challengeresponse')}>
            <i className="fa fa-list-ol large-icon"></i>
            <h2>Setup security questions</h2>
            <p>Security questions and answers allow you to recover a forgotten password</p>
          </div>

          <div className="block" onClick={(e) => this.GoToLink('/mfa')}>
            <i className="fa fa-qrcode large-icon"></i>
            <h2>Setup mobile authentication app</h2>
            <p>Setup mobile app device. If you forget your password you can use your mobile device to authenticate 
               to this site</p>
          </div>          

          <div className="block" onClick={(e) => this.GoToLink('/myaccount')}>
            <i className="fa fa-user large-icon"></i>
            <h2>My account</h2>
            <p>View your account information</p>
          </div>                     
        </div>
      </div>
    )
  }
}

export default Home
