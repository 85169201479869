import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import Loading from '../../misc/Loading.jsx';
import Moment from 'react-moment';

class Challenges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      caughtError: '',
      questions: [],
      last_updated: ''
    }

    this.handleClearAnswers = this.handleClearAnswers.bind(this);
  }

  async handleClearAnswers(event) {
    event.preventDefault();
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    try {
      let formData = new FormData();
      formData.append('authenticity_token', csrf_token)

      let result = await fetch('/challengeresponse.json', {
        method: 'DELETE',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        this.props.history.push("/challengeresponse/new");
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async componentDidMount() {
    try {
      let result = await fetch('/challengeresponse.json', {credentials: 'same-origin'});

      result = await fetchErrorHandler(result);
      const data = await result.json();
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }
      if (data.status == "ok") {
        if (data.data.questions.length == 0) {
          this.props.history.push("/challengeresponse/new");
        } else {
          this.setState({
            loading: false,
            questions: data.data.questions,
            last_updated: data.data.updated
          })
        }
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <Loading loading={this.state.loading} />
      );
    }

    let last_updated = this.state.last_updated;

    if (/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}\s[0-9]{2}\:[0-9]{2}\:[0-9]{2}\s\+[0-9]{4}$/.test(last_updated)) {
      last_updated = <Moment parse="YYYY-MM-DD HH:mm:ss Z" format="DD/MM/YYYY HH:mm">{last_updated}</Moment>;
    }
    
    return (
      <div>
        <h1>Setup challenge/response answers</h1>

        <p>
          You have already setup your challenge/response answers on {last_updated}. 
          If you continue you can re-answer your questions or add more
        </p>

        <h2>Answered questions</h2>

        <ul>
          {this.state.questions.map(function(question, i) {
            return(
              <li key={i}>{question}</li>
            );
          })}
        </ul>

        <Link to='/challengeresponse/new' className="btn btn-primary" >Add more answers</Link> &nbsp;
        <button className="btn btn-primary" onClick={this.handleClearAnswers}>Clear answers</button>
      </div>
    )
  }
}

export default Challenges
