import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';
import Loading from '../../misc/Loading.jsx';
import RenderError from '../../misc/RenderError';
import ChangePasswordForm from '../../misc/ChangePasswordForm';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      complete: false,
      caughtError: '',
      error_message: '',
      verified_password: false,
      current_password: '',
      password_policy_text: [],
      password_policy: {},
      pw_togggles: {}
    }

    this.current_password_input = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
    this.verifyCurrentPasswordSubmit = this.verifyCurrentPasswordSubmit.bind(this);

  }

  handleChange(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.id 

    this.setState({
      [name] : value
    });
  }


  async verifyCurrentPasswordSubmit(event) {
    event.preventDefault();

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));
    let formData = new FormData();
    formData.append('password', this.current_password_input.current.value);
    formData.append('authenticity_token', csrf_token)
    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/changepassword/authenticate_test.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (data.status == "ok") {
        this.setState({
          error_message: '',
          verified_password: true,
          password_policy_text: data.data.password_policy[0],
          password_policy: data.data.password_policy[1],
          loading: false
        });
      } else {
        // Verify that we are still logged in
        let login_check_result = await fetch('/logged_in_check.json', {
          credentials: 'same-origin'
        });
        login_check_result = await fetchErrorHandler(login_check_result);
        const login_check_data = await login_check_result.json();

        if (login_check_data.status == "ok") {
          this.setState({
            error_message: data.message,
            loading: false
          });

          //  We don't update the password dom from state for security reasons
          //  so we need to empty it via a reference
          this.current_password_input.current.value = "";          
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      this.setState({caughtError: error});
    }    
  }

  async changePasswordSubmit(new_password, confirm_password, errorCallback) {
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));
    let formData = new FormData();
    formData.append('password', this.state.current_password);    
    formData.append('new_password', new_password);
    formData.append('confirm_password', confirm_password);
    formData.append('authenticity_token', csrf_token);

    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/changepassword.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();
      
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        this.setState({
          loading: false,
          complete: true,
          error_message: '',           
        });
      } else {
        // Verify that we are still logged in
        let login_check_result = await fetch('/logged_in_check.json', {
          credentials: 'same-origin'
        });
        login_check_result = await fetchErrorHandler(login_check_result);
        const login_check_data = await login_check_result.json();

        if (login_check_data.status == "ok") {
          this.setState({
            error_message: data.message,
            loading: false,          
          });

          errorCallback();
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async componentDidMount() {
    try {
      let result = await fetch('/logged_in_check.json', {credentials: 'same-origin'});

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (data.status == "ok") {
        this.setState({
          loading: false
        })
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  togglePwView(form_id, event) {
    event.preventDefault();

    let pw_togggles = this.state.pw_togggles;
    if (
      typeof pw_togggles[form_id] === 'undefined' || 
      !pw_togggles[form_id]
    ) {
      pw_togggles[form_id] = true;
    } else {
      pw_togggles[form_id] = false;
    }

    this.setState({
      pw_togggles: pw_togggles
    });
  }

  render() {
    let self=this;
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <Loading loading={this.state.loading} />
      );
    }

    if (this.state.complete) {
      return (
        <div>
          <h1>Change password</h1>

          <div className="alert alert-success">Your password has been succesfully changed.</div>

          <Link to='/' className="btn btn-primary">Go back</Link>
        </div>
      );
    }

    if (this.state.verified_password) {


      return(
        <div>
          <h1>Change password</h1>

          <RenderError error_message={this.state.error_message} />
    
          <ChangePasswordForm 
             changePasswordSubmit={this.changePasswordSubmit.bind(this)}
             password_policy_text={this.state.password_policy_text}
             password_policy={this.state.password_policy} 
             show_cancel_button={true}
             cancel_link="/"
             />        
        </div>
      );
    }

    let toggle_pw_classes = "input-group-text fa";
    let toggle_pw_box_class = "password";

    if (
      typeof this.state.pw_togggles['password'] !== 'undefined' &&
      this.state.pw_togggles['password']
    ) {
      toggle_pw_classes += " fa-eye";
      toggle_pw_box_class = "text";
    } else {
      toggle_pw_classes += " fa-eye-slash";
    }


    return (
      <div>
        <h1>Change password</h1>

        <p>
          Please enter your current password to begin the change
        </p>

        <RenderError error_message={this.state.error_message} />

        <form onSubmit={this.verifyCurrentPasswordSubmit}>
          <div className="form-group">
            <label>Current Password</label>
            <div className="input-group">
              <input id="current_password" 
                     type={toggle_pw_box_class}
                     className="form-control"
                     onChange={this.handleChange} 
                     ref={this.current_password_input} />
              <div className="input-group-append">
                <i className={toggle_pw_classes}
                   onClick={(e) => self.togglePwView('password', e)}></i>
              </div>                      
            </div>
          </div>

          <input type="submit" className="btn btn-primary" value="Continue" />
        </form>
      </div>
    )
  }
}

export default ChangePassword
