import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import Loading from '../../misc/Loading.jsx';
import fetchErrorHandler from '../../errors/fetchErrrorHandler.js';
import readCookie from '../../misc/readCookie.js';


class MFAEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      success: false,
      error_message: '',
      loading: true,
      token: {
        serial: props.match.params.mfa_serial,
        description: '',
        type: '',
        email_address: '',
        phone_number: ''
      },
      reverify: false
    }

    this.token_input = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFinalSubmit = this.handleFinalSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.id 

    let token = this.state.token;
    token[name] = value;

    this.setState({
      token : token
    });
  }

  async handleSubmit(event) {
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('mfa_serial', this.state.token.serial);
    formData.append('mfa_description', this.state.token.description);

    if (this.state.token.type == 'email') {
      formData.append('email_address', this.state.token.email_address);
    }

    if (this.state.token.type == 'sms') {
      formData.append('phone_number', this.state.token.phone_number);
    }    

    formData.append('authenticity_token', csrf_token)

    this.setState({
      loading: true
    });

    try {
      let result = fetch('/token.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      this.setState({
        loading: false
      });

      if (data.status == "ok") {
        this.setState({   
          loading: false,
          success: true,
          error_message: '',
          reverify: data.data.reverify
        });
      } else {
        this.setState({ error_message: data.message, loading: false, success: false });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }

    event.preventDefault();
  }  

  async handleFinalSubmit(event) {
    event.preventDefault();

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('token_type', "email");
    formData.append('mfa_serial', this.props.match.params.mfa_serial);
    formData.append('mfa_token', this.token_input.current.value );
    formData.append('authenticity_token', csrf_token)

    this.setState({
      loading: true
    });

    try {
      let result = await fetch('/token/verify.json', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin'
      });

      result = await fetchErrorHandler(result);
      const data = await result.json();

      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }

      if (data.status == "ok") {
        this.setState({   
          loading: false,
          success: true,
          error_message: '',
          reverify: false
        });

        //  We don't update the token dom from state for security reasons
        //  so we need to empty it via a reference
        if (this.token_input.current) {
          this.token_input.current.value = "";          
        }
      } else {
        this.setState({ error_message: data.message, loading: false });
        //  We don't update the token dom from state for security reasons
        //  so we need to empty it via a reference
        if (this.token_input.current) {
          this.token_input.current.value = "";          
        }
      }
    } catch (error) {
      this.setState({caughtError: error});
    }   

  }    

  async handleCancel(event) {
    event.preventDefault();

    this.setState({   
      loading: true,
      success: false,
      error_message: '',
      reverify: false
    });

    this.componentDidMount();

    //  We don't update the token dom from state for security reasons
    //  so we need to empty it via a reference
    this.token_input.current.value = "";         
  }

  async componentDidMount() {
    try {

      let result = await fetch('/token.json?mfa_serial=' + this.state.token.serial, {credentials: 'same-origin'})
      result = await fetchErrorHandler(result);
      const data = await result.json();
      
      if (typeof this.props.resetInactivityTimer !== 'undefined') {
        this.props.resetInactivityTimer();
      }      

      if (data.status == "ok") {
        this.setState({
          loading: false,
          token: data.data.token
        })
      } else {
        throw Error([500, data.message]);
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  renderError() {
    if (this.state.error_message) {
      return(
        <div className="alert alert-danger">{this.state.error_message}</div>
      );
    }
    return;
  }

  renderSuccess() {
    if (this.state.success) {
      return(
        <div className="alert alert-success">The token has been updated</div>
      );
    }
    return;
  }


  renderEmail() {
    if (this.state.token.type == 'email') {
      return (
        <div className="form-group">
          <label>E-mail address</label>
          <input className="form-control" id="email_address" 
                value={this.state.token.email_address} onChange={this.handleChange} />
        </div>        
      );
    }

    return;
  }

  renderPhone() {
    if (this.state.token.type == 'sms') {
      return (
        <div className="form-group">
          <label>Phone number</label>
          <input className="form-control" id="phone_number" 
                value={this.state.token.phone_number} onChange={this.handleChange} />
        </div>        
      );
    }

    return;
  }

  render() {
    var self=this;
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <Loading loading={this.state.loading} />
      );
    }

    if (this.state.reverify) {
      return (
        <div>
          <h1>Edit multi factor authentication</h1>        

          <p>
            You should have recieved a token, please enter it below to validate that it is working correctly.
          </p>
          {this.renderError()}


          <form onSubmit={this.handleFinalSubmit}>
            <div className="form-group">
              <input type="password" id="token" placeholder="Enter multifactor code" className="form-control"
                      ref={this.token_input} autofocus="true"/>
            </div>

            <input type="submit" className="btn btn-primary" value="Register" />&nbsp;
            <button className="btn btn-primary" onClick={this.handleCancel}>Cancel</button>           
          </form>
        </div>        
      );
    }    

    return (
      <div>
        <h1>Edit multi factor authentication</h1>


        <h2>Edit {this.state.token.serial}</h2>

        {this.renderError()}          
        {this.renderSuccess()}        
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label>Description</label>
            <input className="form-control" id="description" 
                value={this.state.token.description} onChange={this.handleChange} />
          </div>

          {this.renderEmail()}
          {this.renderPhone()}

          <input type="submit" className="btn btn-primary" value="Register" />&nbsp;
          <Link to='/mfa' className="btn btn-primary">Cancel</Link>
        </form>
      </div>
    )
  }
    
}

export default MFAEdit
