import React from "react"
import PropTypes from "prop-types";

class ForgotPasswordRenderProgress extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let step = this.props.step;

    let step_1 = "col-xs-3 bs-wizard-step active";
    let step_2 = "col-xs-3 bs-wizard-step disabled";
    let step_3 = "col-xs-3 bs-wizard-step disabled";
    let step_4 = "col-xs-3 bs-wizard-step disabled";

    if (step == 2) {
      step_1 = "col-xs-3 bs-wizard-step complete";
      step_2 = "col-xs-3 bs-wizard-step active";
      step_3 = "col-xs-3 bs-wizard-step disabled";
      step_4 = "col-xs-3 bs-wizard-step disabled";
    } else if (step == 3) {
      step_1 = "col-xs-3 bs-wizard-step complete";
      step_2 = "col-xs-3 bs-wizard-step complete";
      step_3 = "col-xs-3 bs-wizard-step active";            
      step_4 = "col-xs-3 bs-wizard-step disabled";
    } else if (step == 4) {
      step_1 = "col-xs-3 bs-wizard-step complete";
      step_2 = "col-xs-3 bs-wizard-step complete";
      step_3 = "col-xs-3 bs-wizard-step complete";
      step_4 = "col-xs-3 bs-wizard-step active";      
    }

    return (
      <div className="row bs-wizard">
        <div className={step_1}>
          <div className="text-center bs-wizard-stepnum">Step 1</div>
          <div className="progress"><div className="progress-bar"></div></div>
          <a href="javascript:void(0);" className="bs-wizard-dot"></a>
        </div>
              
        <div className={step_2}>
          <div className="text-center bs-wizard-stepnum">Step 2</div>
          <div className="progress"><div className="progress-bar"></div></div>
          <a href="javascript:void(0);" className="bs-wizard-dot"></a>
        </div>
              
        <div className={step_3}>
          <div className="text-center bs-wizard-stepnum">Step 3</div>
          <div className="progress"><div className="progress-bar"></div></div>
          <a href="javascript:void(0);" className="bs-wizard-dot"></a>
        </div>

        <div className={step_4}>
          <div className="text-center bs-wizard-stepnum">Step 4</div>
          <div className="progress"><div className="progress-bar"></div></div>
          <a href="javascript:void(0);" className="bs-wizard-dot"></a>
        </div>          
      </div>
    )
  }
}

export default ForgotPasswordRenderProgress;
